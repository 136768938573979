// StacksActiveListing.js 20220621 j0zf
// StacksListingToken.js 20220621 j0zf
// StacksOwnerToken.js - 20210425 j0zf at ApogeeINVENT
// StacksOwnerTokens.js - 20210424 j0zf at ApogeeINVENT
// StacksToken.js - 20210415 j0zf at ApogeeINVENT
// StacksForm.js - 20210414 j0zf at ApogeeINVENT
// StacksPublish.js - 20210411 j0zf at ApogeeINVENT

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import StacksListingToken from "./StacksListingToken"
import BlockTxns from "../utility/BlockTxns.js"

class StacksActiveListing extends React.Component {
	state = { index: this.props.index, owner: (this.props.owner ? this.props.owner : "0x0000000000000000000000000000000000000000") }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "MarketLogic", "getActiveListingId", { owner: this.state.owner, index: this.state.index }, "getActiveListing_result")
	}

	refresh = () => {
		this.setState({refresh: this.state.refresh+1})
	}

	render() {
		const listingId = Number(BlockTxns.getCacheValue( this, "MarketLogic", "getActiveListingId", "getActiveListing_result", 0))
		return <StacksListingToken listingId={listingId} />
	}
}

export default StacksActiveListing
