// TokenPane.js 20210415 j0zf at ApogeeINVENT

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin';
import { Container, Input } from "reactstrap";
import StacksTokenDetails from "../stacks/StacksTokenDetails"

class TokenPane extends React.Component {
    state = {fields: {tokenId: 0}};
	static contextType = DrizzleContext.Context;

    constructor(props) {
        super(props);
        if (props.queryObject.tokenId) {
            this.state.fields.tokenId = Number(props.queryObject.tokenId);
        }
    }

    getFieldState = (fname) => {
        return (this.state.fields[fname] === undefined ? "" : this.state.fields[fname]);
    }

	handleFieldChange = (e) => {
		var fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({fields});
    }

	render() {
		return (
        <Container>
            <div className="clearfix">
                <div>
                    <h2>Stacks721 Media Token</h2>
                </div>
                {/* 
                <div style={{'width': '60%', 'minWidth': '30rem', 'maxWidth': '40rem', 'float': 'left' }}>
				<div style={{ 'width': '100%', 'minWidth': '16rem', 'maxWidth': '40rem', 'overflow': 'hidden' }}>
				<div style={{ 'width': '100%', 'minWidth': '16rem', 'maxWidth': '40rem', 'overflow': 'auto' }}>
				<div style={{ 'width': '100%', 'minWidth': '16rem', 'maxWidth': '80rem', 'overflow': 'auto' }}>
                */}
				<div style={{ 'width': '100%', 'minWidth': '16rem' }}>
                    <dl className="row">
                        <dt className="col-sm-4 mb-2 pt-2"> Token Id </dt>
                        <dd className="col-sm-8 text-truncate small">
                            <Input className="m-1" type="number" name="tokenId" placeholder="Token Id" value={this.getFieldState('tokenId')} onChange={this.handleFieldChange} min="0" step="1" />
                        </dd>
                    </dl>
                    <StacksTokenDetails tokenId={this.state.fields.tokenId} />
                </div>
                {/* 
                <div style={{'width': '20rem', 'float': 'left'}} className="ml-4 mt-4">
                    <StacksToken tokenId={this.state.fields.tokenId} />
                </div>
                */}
            </div>
        </Container>
		);
	}
}

export default TokenPane;
