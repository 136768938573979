// DynamicPane.js - 20210530 j0zf
// PublishPane.js - 20210411 j0zf

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import { Container, Col, Row, Input } from "reactstrap"
import StacksForm from '../stacks/StacksForm.js'
import StacksData from '../stacks/StacksData.js'
import BlockTxns from '../utility/BlockTxns.js'

class DynamicPane extends React.Component {
	state = { fields: { contract: this.props.contract, method: this.props.method } }
	static contextType = DrizzleContext.Context;

	handleFieldChange = (e) => {
		var fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({fields});
	}

	render() {
		const M = BlockTxns.getMethodAbi(this, this.state.fields.contract, this.state.fields.method)
		const C = (this.context.drizzle.contracts[this.state.fields.contract] ? this.context.drizzle.contracts[this.state.fields.contract] : {
			abi: [ {
				name: "sendFunds",
				type: "function",
				stateMutability: "payable",
				inputs: [],
				outputs: []
			} ]
		})
		if (this.context.debugger) console.log("C => Contract: ", C) 
		const contractNames = Object.keys(this.context.drizzle.contracts)
		contractNames.push("WalletTools")
		const hideMethods =['installProxy', 'installLogic']

		// build list of method names
		var methodNames = []
		if (C && C.abi) {
			for(var aa in C.abi) {
				const abiRow = C.abi[aa];
				if (abiRow.name && abiRow.type === 'function' && !hideMethods.includes(abiRow.name)) {
					methodNames.push(abiRow.name);
				}
			}
		} 

		var controlsO = ( <Col>
				<Row className="ml-3" style={{ width: "77%"}}>
				<Input className="mr-4" name="contract" type="select" onChange={this.handleFieldChange} value={this.state.fields.contract}>
					<option value="">Select Contract</option>
					{contractNames.map((contractName, i) => {
						return <option key={i} value={contractName}>{BlockTxns.purdyCamel(contractName)}</option>;
					})}
				</Input>
				</Row>
				<Row className="ml-3" style={{ width: "77%"}}>
				<Input className="mr-4" name="method" type="select" onChange={this.handleFieldChange} value={this.state.fields.method}>
					<option value="">Select Method</option>
					{methodNames.map((methodName, i) => {
						return <option key={i} value={methodName}>{BlockTxns.purdyCamel(methodName)}</option>;
					})}
				</Input>
				</Row>
		</Col> )
		
		// if(!M && this.state.fields.contract !== 'WalletTools') {
		if(!M) {
			return <div className="mb-4 mt-4">{controlsO}</div>
		}
		
		return (
		<Container>
			<Row>
				<h2>{this.props.headline}</h2>
			</Row>
			<Row className="mb-4">
				{ this.props.hideControls === true ? "" : controlsO }
			</Row>
			<Row>
				{ M && M.stateMutability && ( M.stateMutability === 'view' || M.stateMutability === 'pure' ) ? 
					<Col>
						<StacksData contract={this.state.fields.contract} method={this.state.fields.method} tokenPane={this.props.tokenPane} queryObject={this.props.queryObject ? this.props.queryObject : {}} />
					</Col>
				:
					<Col>
						<StacksForm contract={this.state.fields.contract} method={this.state.fields.method} tokenPane={this.props.tokenPane} queryObject={this.props.queryObject ? this.props.queryObject : {}} />
					</Col>
				}
			</Row>
		</Container>
		)
	}
}

export default DynamicPane;
