// StacksListingToken.js 20220621 j0zf
// StacksOwnerToken.js - 20210425 j0zf at ApogeeINVENT
// StacksOwnerTokens.js - 20210424 j0zf at ApogeeINVENT
// StacksToken.js - 20210415 j0zf at ApogeeINVENT
// StacksForm.js - 20210414 j0zf at ApogeeINVENT
// StacksPublish.js - 20210411 j0zf at ApogeeINVENT

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import StacksToken from "../stacks/StacksToken"
import StacksSeries from "../stacks/StacksSeries"
import BlockTxns from "../utility/BlockTxns.js"

class StacksListingToken extends React.Component {
	state = { listingId: this.props.listingId }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "MarketLogic", "getListing", { 'listingId': this.state.listingId }, "getListing_result")
	}

	render() {
		const listing = BlockTxns.getCacheValue( this, "MarketLogic", "getListing", "getListing_result", {})
		if (listing.tokenId) {
			return <StacksToken tokenId={listing.tokenId} />
		} else if (listing.seriesId) {
			return <StacksSeries listing={listing} />
		}
		return <div>...</div>
	}
}

export default StacksListingToken
