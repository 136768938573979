// StacksLib.js j0zf 2022 0224
import React from "react"
import BlockTxns from "../utility/BlockTxns.js"

const StacksLib = {

	purdyCamel: (str) => {
        return BlockTxns.purdyCamel(str)
    },

    drawMainLogo: (Settings) => {
        var logoFilename = Settings.networkName !== "" ? "./images/dAppMainLogo_"+Settings.networkName+".png" : "./StacksNETioMainLogo.png"
        var altText = Settings.networkName !== "" ? "CryptoComics "+StacksLib.getNetworkName(Settings)+" dApp Wallet" : "StacksNET.io Stacks and Stacks and Stacks of Media"
        return <img height="40" src={logoFilename} alt={altText} />
        // return <img height="36" src="./StacksNETioMainLogo.png" alt="StacksNET.io Stacks and Stacks and Stacks of Media" />
    },

    getNetworkName: (Settings) => {
        switch(Settings.networkName) {
            case 'stacksnet': return 'StacksNET'
            case 'stackstest': return 'StacksTEST'
            case 'mumbai': return 'Mumbai'
            case 'polygon': return 'Polygon'
            case 'ethereum': return 'Ethereum'
            case 'goerli': return 'Goerli'
            default: return 'Unknown'
        }
    },

    currency: (nativeAmount, web3) => {
        // nativeAmount : converted from pegged value to native blockchain Wei
        return web3.utils.fromWei(new web3.utils.BN(nativeAmount), 'ether')
    },

    // <> todo
    // currencyWei: (amount, peg, web3) => {
    // },

    currencySymbol: (peg) => {
        switch(peg) {
            case 0: return <span role="img" aria-label="Stacks Credit">&#128218;</span>
            case 1: return <span>$</span>
            default: return <span>??</span>
        }
    }

}

export default StacksLib
