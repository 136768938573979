// StacksOwnerTokens.js - 20210424 j0zf at ApogeeINVENT
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import StacksOwnerToken from "../stacks/StacksOwnerToken"
import equal from 'fast-deep-equal'
import { CardDeck, Container, PaginationLink, PaginationItem, Pagination } from "reactstrap"
import BlockTxns from "../utility/BlockTxns.js"

class StacksOwnerTokens extends React.Component {
	state = { owner: this.props.owner, page: this.props.page, pageSize: 12 }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "Stacks721", "balanceOf", {owner: this.state.owner}, "balanceOf_results")
	}

	render() {
		const drizzleState = this.context.drizzleState
		const balanceOf = Number(BlockTxns.getCacheValue(this, "Stacks721", "balanceOf", "balanceOf_results", 0))
		const pageSize = Number(this.state.pageSize)
		const pageCount = Math.ceil( balanceOf / pageSize )
		const currNumber = this.state.page && this.state.page > 1 ? Number(this.state.page) : 1
		const prevNumber = currNumber > 1 ? currNumber - 1 : 1
		const nextNumber = currNumber < pageCount ? currNumber + 1 : currNumber
		var pageNumbers = []

		for (var xx=1; xx<=pageCount; xx++) {
			if (xx === 1 || xx === pageCount || xx === currNumber || xx === prevNumber || xx === nextNumber
				|| xx === Math.floor(currNumber/2) || xx === currNumber + Math.floor((pageCount-currNumber)/2)
				|| xx === currNumber - Math.floor(currNumber/4) || xx === currNumber + Math.floor((pageCount-currNumber)/4)
			) {
				pageNumbers.push(xx)
			}
		}

		var indexes = []
		const tokStart = (balanceOf-1) - ((currNumber-1) * pageSize)
		const tokEnd = (tokStart - pageSize + 1 < 0 ? 0 : tokStart - pageSize + 1)
		for (var i=tokStart; i >= tokEnd; i--) {
			indexes.push(i)
		}

		return ( <Container>
			<div className="clearfix">
				<h5 style={{'float': 'left'}} className="text-muted">
					&nbsp;
					{drizzleState.accounts[0] !== this.state.owner ? <span>&nbsp; They Have &nbsp;</span> : <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp; You Have &nbsp;</span>}
					{balanceOf} Media Tokens
					&infin;
					Listing 
					{ tokStart < 0 ? 
						" O.o Want?! "
						: 
						<span> {tokStart+1} to {tokEnd+1} </span>
					}
				</h5>
				<Pagination style={{'float': 'right'}}>
					<PaginationItem  disabled={currNumber <= 1}>
						<PaginationLink previous href={"?owner="+this.state.owner+"&page="+prevNumber} />
					</PaginationItem>
					<PaginationItem disabled={currNumber >= pageCount}>
						<PaginationLink next href={"?owner="+this.state.owner+"&page="+nextNumber} />
					</PaginationItem>
				</Pagination>
			</div>
			<CardDeck>
				{indexes.map((index_, i) => {
					return <StacksOwnerToken key={i} owner={this.state.owner} index={index_} />;
				})}
			</CardDeck> 
			<div className="mt-3">
			<Pagination>
				<PaginationItem  disabled={currNumber <= 1}>
					<PaginationLink previous href={"?owner="+this.state.owner+"&page="+prevNumber} />
				</PaginationItem>
				{pageNumbers.map(( pg, i) => {
					return (
						<PaginationItem key={i} disabled={pg === currNumber}>
							<PaginationLink key={i} href={"?owner="+this.state.owner+"&page=" + pg}> {pg} </PaginationLink>
						</PaginationItem>
					);
				})}
				<PaginationItem disabled={currNumber >= pageCount}>
					<PaginationLink next href={"?owner="+this.state.owner+"&page="+nextNumber} />
				</PaginationItem>
			</Pagination>
			</div>
		</Container> )
	}

}

export default StacksOwnerTokens
