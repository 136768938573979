// StacksTokenDetails.js 20221109 j0zf at apogeeinvent.com
// StacksTokenDetails.js 2010418 j0zf at ApogeeINVENT
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { ButtonGroup, Button, Container, Badge, Card, Row, Col, CardImg, CardHeader, CardText, CardFooter } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
import StrangeDice from "../utility/StrangeDice.js"
import StacksEstPrice from "../stacks/StacksEstPrice.js"

class StacksTokenDetails extends React.Component {
	state = { tokenId: this.props.tokenId, contractId: 1, adminUI: false }
	static contextType = DrizzleContext.Context
	componentWillMount() {
		if (localStorage.adminUI === "true") {
			this.setState({ adminUI: true})
		}
	}
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (Number(this.state.tokenId) < 1) return
		BlockTxns.contractCacheCall(this, "Stacks721", "getTokenData", { tokenId: this.state.tokenId }, "getTokenData_1")
		BlockTxns.contractCacheCall(this, "Stacks721", "tokenURI", { tokenId: this.state.tokenId }, "tokenURI_1")
	}
	render() {
		const tokenData = BlockTxns.getCacheValue(this, "Stacks721", "getTokenData", "getTokenData_1", { seriesId: 0 })
		const tokenURI = BlockTxns.getCacheValue(this, "Stacks721", "tokenURI", "tokenURI_1", "")
		return <StacksTokenDetails2 tokenId={this.state.tokenId} contractId={this.state.contractId} tokenData={tokenData} tokenURI={tokenURI} />
	}
}

class StacksTokenDetails2 extends React.Component {
	state = { tokenId: this.props.tokenId, contractId: this.props.contractId, tokenData: this.props.tokenData, tokenURI: this.props.tokenURI }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		if (Number(this.state.tokenId) < 1) return
		BlockTxns.contractCacheCall(this, "MarketLogic", "getContractListingId", { contractId: this.state.contractId, idType: "token",  id: this.state.tokenId }, "getContractListingId_token")
		BlockTxns.contractCacheCall(this, "MarketLogic", "getContractListingId", { contractId: this.state.contractId, idType: "series",  id: this.state.tokenData.seriesId }, "getContractListingId_series")
		BlockTxns.contractCacheCall(this, "AppLogic", "hasRole", { roleType: 2, account: this.context.account }, "hasManagerRole")
	}

	navToggle = () => {
		this.setIsOpen(!this.state.isOpen);
	}

	setIsOpen = (flg) => {
		this.setState({isOpen:flg});
	}

	render() {
		const listingId = BlockTxns.getCacheValue(this, "MarketLogic", "getContractListingId", "getContractListingId_token", 0)
		const seriesListingId = BlockTxns.getCacheValue(this, "MarketLogic", "getContractListingId", "getContractListingId_series", 0)
		const hasManagerRole = BlockTxns.getCacheValue(this, "AppLogic", "hasRole", "hasManagerRole", false)
		return <StacksTokenDetails3 tokenId={this.state.tokenId} contractId={this.state.contractId} tokenData={this.state.tokenData} tokenURI={this.state.tokenURI} listingId={listingId} seriesListingId={seriesListingId} hasManagerRole={hasManagerRole} />
	}
}

class StacksTokenDetails3 extends React.Component {
	state = { tokenId: this.props.tokenId, contractId: this.props.contractId, tokenData: this.props.tokenData, tokenURI: this.props.tokenURI, listingId: this.props.listingId, seriesListingId: this.props.seriesListingId, tokenCdnData: {}, isOpen: false, hasManagerRole: this.props.hasManagerRole }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		if (Number(this.state.tokenId) < 1) return
		console.log("StacksToken::queryContracts() seriesId =>", this.state.tokenData.seriesId) // #debugger
		BlockTxns.contractCacheCall(this, "Stacks721", "ownerOf", { tokenId: this.state.tokenId }, "ownerOf_1")
		BlockTxns.contractCacheCall(this, "Stacks721", "getSeries", { seriesId: this.state.tokenData.seriesId }, "getSeries_1")
		BlockTxns.contractCacheCall(this, "MarketLogic", "getListing", { listingId: this.state.listingId }, "getListing_1")
		BlockTxns.contractCacheCall(this, "MarketLogic", "getListing", { listingId: this.state.seriesListingId }, "getListing_series")
		this.loadTokenJson()
	}

	loadTokenJson = () => {
		try {
			const tokenURI = this.state.tokenURI
			if (this.state.tokenURI !== "") {
				fetch(tokenURI)
				.then((resp) => resp.json())
				.then((respJson) => {
					this.setState({ tokenCdnData: respJson })
				}).catch(error => {
					throw(error)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	navToggle = () => {
		this.setIsOpen(!this.state.isOpen)
	}

	setIsOpen = (flg) => {
		this.setState({isOpen:flg})
	}

	render () {
		const drizzleState = this.context.drizzleState
		const tokenOwner = BlockTxns.getCacheValue(this, "Stacks721", "ownerOf", "ownerOf_1", "")
		const tokenData = this.state.tokenData
		const seriesData = BlockTxns.getCacheValue(this, "Stacks721", "getSeries", "getSeries_1", {})
		const listing = BlockTxns.getCacheValue(this, "MarketLogic", "getListing", "getListing_1", { price: 0, peg: 0, nativePrice: 0 })
		const seriesListing = BlockTxns.getCacheValue(this, "MarketLogic", "getListing", "getListing_series", { price: 0, peg: 0, nativePrice: 0 })
		const isOwner = (drizzleState.accounts[0] === tokenOwner ? true : false)

		listing.price = ( listing.price !== undefined ? listing.price : 0 )
		listing.peg = ( listing.peg !== undefined ? listing.peg : 0 )
		listing.nativePrice = ( listing.nativePrice !== undefined ? listing.nativePrice : 0 )
		seriesListing.price = ( seriesListing.price !== undefined ? seriesListing.price : 0 )
		seriesListing.peg = ( seriesListing.peg !== undefined ? seriesListing.peg : 0 )
		seriesListing.nativePrice = ( seriesListing.nativePrice !== undefined ? seriesListing.nativePrice : 0 )

		const tokenCdnData = this.state.tokenCdnData
		var issueCoverUrl = "";
		var softcopyLink = "";
		var issueTitle = "";
		var creatorProfileLink = "";
		var creatorUsername = "";
		var seriesTitle = "";
		if (tokenCdnData.id+"" === this.state.tokenId+"") {
			issueCoverUrl = tokenCdnData.image;
			softcopyLink = tokenCdnData.external_link;
			issueTitle = tokenCdnData.issue_title;
			creatorProfileLink = tokenCdnData.creator_link;
			creatorUsername = tokenCdnData.creator_name;
			seriesTitle = tokenCdnData.series_title;
		}

		// const buyNewLink = "./forms?contract=SalesLogic&method=buyNew&listingId=" + seriesListing.listingId + "&_payableAmount_=" + seriesListing.nativePrice + "&to=" + drizzleState.accounts[0]
		const buyNewLink = "./buynew?listingId=" + seriesListing.listingId + "&_payableAmount_=" + seriesListing.nativePrice + "&to=" + drizzleState.accounts[0]
		// const buyLink = "./forms?contract=SalesLogic&method=buy&listingId=" + listing.listingId + "&_payableAmount_=" + listing.nativePrice + "&to=" + drizzleState.accounts[0]
		const buyLink = "./buy?listingId=" + listing.listingId + "&_payableAmount_=" + listing.nativePrice + "&to=" + drizzleState.accounts[0]
		const c_Stacks721_address = this.context.drizzle.contracts['Stacks721'].address
		const c_StacksStore_address = this.context.drizzle.contracts['StacksStore'].address
		const seriesDetailsLink = './series?seriesId=' + tokenData.seriesId
		const blockExplorerTokenLink = this.context.Settings.BlockExplorerUrl+"token/"+c_Stacks721_address+"?a="+this.state.tokenId
		// const setSaleLink =  "./forms?contract=SalesLogic&method=setSale&listingId="+ this.state.listingId +"&contractId="+ this.state.contractId +"&owner="+ drizzleState.accounts[0] +"&tokenId=" + Number(this.state.tokenId) + "&price=" + listing.price + "&peg=" + listing.peg 
		const setSaleLink =  "./forms?contract=SalesLogic&method=setSale&listingId="+ this.state.listingId +"&contractId="+ this.state.contractId +"&owner="+ tokenOwner +"&tokenId=" + Number(this.state.tokenId) + "&price=" + listing.price + "&peg=" + listing.peg 
		const cancelListingLink =  "./forms?contract=MarketLogic&method=cancelListing&listingId="+ this.state.listingId
		// const cancelListingLink =  "./forms?contract=MarketLogic&method=cancelListing&listingId="+ this.state.listingId +"&contractId="+ this.state.contractId +"&owner="+ tokenOwner +"&tokenId=" + Number(this.state.tokenId) + "&price=" + listing.price + "&peg=" + listing.peg 
		// const setMintableSaleLink =  "./forms?contract=SalesLogic&method=setMintable&listingId="+ this.state.listing.listingId +"&contractId="+ seriesListing.contractId +"&owner="+ seriesListing.owner +"&seriesId=" + Number(this.state.listing.seriesId) + "&price=" + seriesListing.price + "&peg=" + seriesListing.peg 
		const safeTransferLink =  "./forms?contract=Stacks721&method=safeTransferFrom&from="+ tokenOwner +"&to=&tokenId="+ this.state.tokenId
		const openSeaLink = "https://opensea.io/" + tokenOwner
		const stacksGoProfile = "https://cryptocomics.com/stacks_goprofile?account=" + tokenOwner
		const blockExplorerTokenOwnerLink = this.context.Settings.BlockExplorerUrl + "address/" + tokenOwner
		const blockExplorerSeriesOwnerLink = this.context.Settings.BlockExplorerUrl + "address/" + seriesListing.owner
		const publisherHoldingsLink = "./holdings?account=" + seriesListing.owner
		const ownerHoldingsLink = "./holdings?account=" + tokenOwner

		return (
			<Container>
			<Row>
			<Col>
				<Row>
					<h2 className="ml-2 mt-2">{issueTitle}</h2>
				</Row>
				<Row>
					<h5 className="text-muted"><span className="text-muted">{seriesTitle}</span> <a href={creatorProfileLink} target="" rel="noopener noreferrer">{creatorUsername}</a></h5>
				</Row>
				<Row>
					<ButtonGroup size="lg" style={{ width: '100%'}} className="mt-4 mb-1">
						<Button outline href={softcopyLink}>View Content</Button>
						{ isOwner ? 
							<Button outline href={setSaleLink}>
								Set Sale <span>&#8470;</span>{tokenData.number} <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span>
							</Button>
						: "" }

						{ isOwner && this.state.listingId > 0 ? 
							<Button outline href={cancelListingLink}>
								Cancel
								Sale
							</Button>
						: ( isOwner ?
							<Button outline href={safeTransferLink}>
								Send
							</Button>
							: "" )
						}

						{ listing.price+"" === "0" || isOwner ? 
							( ""+seriesListing.price !== "0" ?
								<Button outline href={buyNewLink} color="primary">Buy New</Button>
								: ""
							)
						 : 
							<Button outline href={buyLink} color="primary">Buy <span>&#8470;</span>{tokenData.number}</Button>
						}
					</ButtonGroup>
				</Row>
				<Row>
					<h6 className="text-muted ml-auto mr-auto mb-4 mt-1">
						{ listing.price+"" === "0" ? 
							<span>
								Unlisted | New Price
								{BlockTxns.currDraw(this, seriesListing.nativePrice)}
								{' ' + this.context.Settings.curr}
								{' | Est. '}
								<StacksEstPrice price={ seriesListing.price } peg={ seriesListing.peg } />
								{' USD '}
							</span>
							: 
							<span>
								{BlockTxns.currDraw(this, listing.nativePrice)}
								{' ' + this.context.Settings.curr}
								{' | Est. Price '}
								<StacksEstPrice price={ listing.price } peg={ listing.peg } />
								{' USD '}
							</span>
							}
					</h6>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1">
						Stacks721 | Token Id  {' '}
						<a href={blockExplorerTokenLink} target="_blank" rel="noopener noreferrer" ><span>&#8599;</span>[ {this.state.tokenId} ]</a>
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1">
						<a href={seriesDetailsLink} rel="noopener noreferrer" >
							{seriesData.symbol}
						</a>
						{' | '} 
						<span>&#8470;</span>{tokenData.number} 
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1">
						Issued {seriesData.issued} 
						{'  |  '}
						Limited {seriesData.limit}
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1 text-truncate">
						{'Token Owner '}
						<a href={"./listings?owner="+tokenOwner}>{ isOwner ? <span>Your Token<span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span></span> : tokenOwner}</a>
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1 text-truncate">
						{ this.context.account === seriesListing.owner ?
							<span>Your Series <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span> </span>
						:
							"Series Owner "
						}
						<a href={"./listings?owner="+seriesListing.owner}>{seriesListing.owner}</a>
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1 text-truncate">
						{'Token Contract '}
						<a href={this.context.Settings.BlockExplorerUrl+"address/"+c_Stacks721_address} target="_blank" rel="noopener noreferrer" ><span>&#8599;</span>{c_Stacks721_address}</a>
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1 text-truncate">
						{'Store Contract '}
						<a href={this.context.Settings.BlockExplorerUrl+"address/"+c_StacksStore_address} target="_blank" rel="noopener noreferrer" ><span>&#8599;</span>{c_StacksStore_address}</a>
					</h7>
				</Row>
				<Row>
					<hr className="text-muted ml-auto mr-auto mb-4 mt-1" />
				</Row>
				<Row className="overflow-auto text-center">
					<div className="text-center">
						{ tokenCdnData.attributes ? tokenCdnData.attributes.map( (obj, i) => {
							return ( (obj.value !== undefined && obj.value !== "") ? 
									<Badge key={i} className="" style={{ padding: '0.6rem', margin: '0.25rem', fontSize: '1.2rem', backgroundColor: '#3DB1AD', opacity: '0.8' }}> <strong>{obj.trait_type}</strong> <br /> <em>{obj.value}</em> </Badge> 
								: "" )
						}) : "" }
					</div>
				</Row>
				<Row>
					<hr className="text-muted ml-auto mr-auto mb-4 mt-1" />
				</Row>

				{ this.state.hasManagerRole ? 
				<Row>
					<div className="text-muted text-small ml-auto mr-auto mb-1 mt-1 text-truncate">
						<a href={setSaleLink}>Set Sale</a>
						{' | '}
						<a href={stacksGoProfile} rel="noopener noreferrer">Profile</a>
						{' | '}
						<a href={blockExplorerSeriesOwnerLink} rel="noopener noreferrer" target="_blank"><span>&#8599;</span>Publisher</a>
						{' | '}
						<a href={publisherHoldingsLink} rel="noopener noreferrer" target="">pH</a>
						{' | '}
						<a href={blockExplorerTokenOwnerLink} rel="noopener noreferrer" target="_blank"><span>&#8599;</span>Owner</a>
						{' | '}
						<a href={ownerHoldingsLink} rel="noopener noreferrer" target="">oH</a>
						{' | '}
						<a href={openSeaLink} rel="noopener noreferrer" target="_blank"><span>&#8599;</span>Sea</a>
					</div>
				</Row>
				: "" }

			</Col>
			<Col xs s md lg xl="5">
				<Card style={{ width: '100%', minWidth: '16rem', maxWidth: '40rem' }} className="mb-4">
					<CardHeader className="m-1 p-1">
						<Row className="flex-nowrap">
							<Col className="text-center">
								<div className="ml-2 overflow-hidden pl-auto pr-auto text-center">
									{seriesData.symbol} 
									&nbsp;|&nbsp;
									{tokenData.number > 0 ? 
										<span>
											<span>&#8470;</span>
											{tokenData.number}
											{" "}
											{ BlockTxns.currSymbol(this.context.Settings.curr, {}) }
										</span> 
									:
										<span>
											<StrangeDice diceDo={{}} />
										</span>
									}
									{ isOwner ? <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span> : '' }
								</div>
							</Col>
						</Row>
					</CardHeader>

					<a rel="noopener noreferrer" href={softcopyLink}>
						<CardImg top width="100%" src={issueCoverUrl} alt={issueTitle} />
					</a>

					<CardFooter>
						<CardText tag="small" className="text-muted">
							<Row>
								<Col className="text-center">
									<div className="ml-auto mr-auto text-center"> 
										Stacks721 | Token Id {this.state.tokenId}
									</div>
								</Col>
							</Row>
						</CardText>
					</CardFooter>

				</Card>
			</Col>
			</Row>
			</Container>
		)
	}
}

export default StacksTokenDetails
