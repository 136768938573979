// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { Container, Col, Row, Spinner, Input, Button, Alert, Label, InputGroupAddon, InputGroup } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from "fast-deep-equal"
import StacksToken from './StacksToken.js'
import StrangeDice from "../utility/StrangeDice.js"
import StacksListingToken from "./StacksListingToken.js"
import BlockTxns from "../utility/BlockTxns.js"
import StacksEstPrice from "../stacks/StacksEstPrice.js"
import StacksPrice from "../stacks/StacksPrice.js"

class StacksForm extends React.Component {
	state = { 
		contract: this.props.contract, 
		method: this.props.method, 
		fields: this.props.queryObject, 
		processingFlag: false,
		_payableDisplay_: '',
		acceptsPayable: false
	}
	static contextType = DrizzleContext.Context

	// pegCurrs = ['Credits', 'Follows USD']
	pegCurrs = [ this.context.Settings.curr, 'Follows USD' ]

	accountTypes = [
		{ n: "Royalties", v: 1 },
		{ n: "Sales", v: 2 },
		{ n: "General", v: 3 },
		{ n: "Bonuses", v: 4 },
		{ n: "Refunds", v: 5 },
		{ n: "Bids Escrow", v: 6 },
		{ n: "Trades Escrow", v: 7 },
		{ n: "Auctions Escrow", v: 8 },
		{ n: "Tax Escrow", v: 9 },
		{ n: "Withdrawal Escrow", v: 10 },
	]

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			var fields = this.queryObject
			var props = this.props
			this.setState({ ...props, fields }, this.queryContracts)
		}
	}

	queryContracts = () => {
		if (this.context.initialized) {
			BlockTxns.cacheAccountBalance(this, this.context.account)
		}
	}

	getFieldState = (fname) => {
		return ( ( this.state.fields === undefined || this.state.fields[fname] === undefined ) ? "" : this.state.fields[fname] )
	}

	handleFieldChange = (e) => {
		var fields = this.state.fields
		fields[e.target.name] = e.target.value
		this.setState({ fields })
	}

	handleSubmit = (e) => {
		e.preventDefault()
		this.setState({ processingFlag: true }, () => {
			if ( this.state.contract === 'WalletTools') {
				switch (this.state.method) {
					case 'sendFunds':
						BlockTxns.walletToolsSendFunds(this, this.state.contract, this.state.method, this.state.fields, "StacksFormKey")
						break
					default:
						console.log('Error Unexpected Method Called by WalletTools ('+this.state.method+')')
				}
			} else {
				BlockTxns.contractSend(this, this.state.contract, this.state.method, this.state.fields, "StacksFormKey")
			}
		})
	}

	handleClearProcessing = (e) => {
		e.preventDefault()
		this.setState({ processingFlag: false })
	}

	renderInput(inp, i) {
		const w3u = this.context.drizzle.web3.utils
		var pegI = "0"
		var readOnly = false
		switch(inp.name) { 
			case 'accountType':
				//readOnly = this.state.method && ["withdrawHoldings"].includes(this.state.method) ? true : false
				return (<div key={i}> 
					<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
					<Input className="m-1" key={i} readOnly={readOnly} type="select" min="0" step="1" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange}>
						<option>Select</option>
						{
							this.accountTypes.map( (obj, i) => {
								return <option key={i} value={obj.v}>{obj.n}</option>
							})
						}
					</Input>
					</Label>
				</div>)

			case 'withdrawMode':
				return (<div key={i}> 
					<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
					<Input className="m-1" key={i} type="select" min="0" step="1" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange}>
						<option value="1" > Direct Withdrawal </option>
					</Input>

					</Label>
				</div>)

			case 'listingId':
				readOnly = this.state.method && ["setSale", "setMintable", "buyNew", "buy"].includes(this.state.method) ? true : false
				return (<div key={i}> 
					<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
					<Input className="m-1" key={i} readOnly={readOnly} type="number" min="0" step="1" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange} />
					</Label>
				</div>)
			case 'tokenId':
					readOnly = this.state.method && ["setSale", "setMintable", "buyNew", "buy"].includes(this.state.method) ? true : false
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
						<Input className="m-1" key={i} readOnly={readOnly} type="number" min="0" step="1" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange} />
						</Label>
					</div>)
			case 'seriesId':
			case 'id':
			case 'expiry':
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
						<Input className="m-1" key={i} type="number" min="0" step="1" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange} />
						</Label>
					</div>)

			case 'contractId':
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
						<Input className="m-1" key={i} type="select" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange} >
							<option value="1"> EC0MICZ </option>
						</Input>
						</Label>
					</div>)

			case 'peg':
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> Currency {BlockTxns.purdyCamel(inp.name)} 
						<Input className="m-1" key={i} type="select" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handlePriceChange} >
							{/*<option value="0"> Stacks Credits </option>*/}
							<option value="0"> { this.context.Settings.curr } (native) </option>
							<option value="1"> Follow USD </option>
						</Input>
						</Label>
					</div>)

			case 'price':
					var _input_price = this.state._input_price
					pegI = this.getFieldState('peg')
					var priceO = <span> 0.00 </span>
					try {
						switch (pegI) {
							case "0": 
							_input_price = this.state._input_price !== undefined ? this.state._input_price.replace(/[^0-9.]/g, "") : this.state.fields.price ? w3u.fromWei(this.state.fields.price, 'ether') : 0
							priceO = <span> { _input_price } { this.pegCurrs[pegI] } </span>
							break
							case "1": 
							_input_price = this.state._input_price !== undefined ? this.state._input_price.replace(/[^0-9.]/g, "") : this.state.fields.price ? this.state.fields.price / 100 : 0
							priceO = <span> { _input_price } { this.pegCurrs[pegI] } </span>
							break
							default: priceO = <span>Unsupported</span>
							break
						}
					} catch (err) {
						console.log("ERROR renderInput case price", err)
					}
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> { BlockTxns.purdyCamel(inp.name) } 
						<InputGroup>
							<Input className="m-1" key={i} type="text" name={'_input_'+inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={_input_price} onChange={this.handlePriceChange} />
							<InputGroupAddon addonType="append">{ BlockTxns.currSymbol(this.context.Settings.curr, { height: '40', className: "pt-2" }, pegI) }</InputGroupAddon>
						</InputGroup>
						</Label> 
						{ priceO }
						<StacksEstPrice price={ this.state.fields.price } peg={ this.state.fields.peg } />
					</div>)

			case 'amount':
					var _input_amount = this.state._input_amount
					pegI = "0"
					var amountO = <span> 0.00 </span>
					try {
						_input_amount = this.state._input_amount !== undefined ? this.state._input_amount.replace(/[^0-9.]/g, "") : this.state.fields.amount ? w3u.fromWei(this.state.fields.amount, 'ether') : 0
						amountO = <span> { _input_amount } { this.pegCurrs[pegI] } </span>
					} catch (err) {
						console.log("ERROR renderInput case amount", err)
					}
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> { BlockTxns.purdyCamel(inp.name) } 
						<InputGroup>
							<Input className="m-1" key={i} type="text" name={'_input_'+inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={_input_amount} onChange={this.handleAmountChange} />
							<InputGroupAddon addonType="append">{ BlockTxns.currSymbol(this.context.Settings.curr, { height: '40', className: "pt-2" }, pegI) }</InputGroupAddon>
						</InputGroup>
						</Label> 
						{ amountO }
						<StacksEstPrice price={ this.state.fields.amount } peg={ "0" } />
					</div>)

			default: 
				switch(inp.type) {
					case "uint256[5]":
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
						<Input className="m-1" key={i+"_0"} type="text" name={inp.name+"[0]"} placeholder={BlockTxns.purdyCamel(inp.name+" 1")} value={this.getFieldState(inp.name+"[0]")} onChange={this.handleFieldChange} />
						<Input className="m-1" key={i+"_1"} type="text" name={inp.name+"[1]"} placeholder={BlockTxns.purdyCamel(inp.name+" 2")} value={this.getFieldState(inp.name+"[1]")} onChange={this.handleFieldChange} />
						<Input className="m-1" key={i+"_2"} type="text" name={inp.name+"[2]"} placeholder={BlockTxns.purdyCamel(inp.name+" 3")} value={this.getFieldState(inp.name+"[2]")} onChange={this.handleFieldChange} />
						<Input className="m-1" key={i+"_3"} type="text" name={inp.name+"[3]"} placeholder={BlockTxns.purdyCamel(inp.name+" 4")} value={this.getFieldState(inp.name+"[3]")} onChange={this.handleFieldChange} />
						<Input className="m-1" key={i+"_4"} type="text" name={inp.name+"[4]"} placeholder={BlockTxns.purdyCamel(inp.name+" 5")} value={this.getFieldState(inp.name+"[4]")} onChange={this.handleFieldChange} />
						</Label>
					</div>)

					default:
					return (<div key={i}> 
						<Label size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
						<Input className="m-1" key={i} type="text" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange} />
						</Label>
					</div>)
				}
		}
	}

	handlePriceChange = (e) => {
		const w3u = this.context.drizzle.web3.utils
		var fields = this.state.fields
		var _input_price = e.target.name === '_input_price' ? e.target.value : this.state._input_price
		fields.peg = e.target.name === 'peg' ? e.target.value : fields.peg

		try {
			switch (fields.peg) {
				case '0': fields['price'] = w3u.toWei(_input_price, 'ether').toString()
				break
				case '1': fields['price'] = isNaN(_input_price) ? "0" : Math.ceil(_input_price * 100) + ""
				break
				default: fields['price'] = _input_price
			}
		} catch (err) {
			fields['price'] = "0"
			console.log("ERROR in handlePriceChange()", err)
		}
		this.setState({ fields, _input_price })
	}

	handleAmountChange = (e) => {
		const w3u = this.context.drizzle.web3.utils
		var fields = this.state.fields
		var _input_amount = e.target.name === '_input_amount' ? e.target.value : this.state._input_amount
		try {
			fields['amount'] = w3u.toWei(_input_amount, 'ether').toString()
		} catch (err) {
			fields['amount'] = "0"
			console.log("ERROR in handlePriceChange()", err)
		}
		this.setState({ fields, _input_amount })
	}

	handleAcceptsPayable = (e) => {
		this.setState({ acceptsPayable: true })
	}

	handlePayableChange = (e) => {
		const w3u = this.context.drizzle.web3.utils
		var fields = this.state.fields
		if (e.target.name === '_payableDisplay_') {
			try {
				fields["_payableAmount_"] = w3u.toWei(e.target.value, 'ether')
			} catch (err) {
				fields["_payableAmount_"] = new w3u.BN("0")
			}
			const _payableDisplay_ = e.target.value
			this.setState({ fields, _payableDisplay_ })
		} else {
			console.log("ERROR handlePayableChange called by wrong field.", e)
		}
	}

	renderPayable() {
		// _payableAmount_ and _payableDisplay_
		const w3u = this.context.drizzle.web3.utils
		var _payableAmount_ = this.getFieldState("_payableAmount_")
		var _payableAmountDisplayable_ = 0
		try {
			_payableAmountDisplayable_ = w3u.fromWei(_payableAmount_, 'ether').toString()
		} catch (err) {
			_payableAmountDisplayable_ = new w3u.BN("0")
		}
		var _payableDisplay_ = this.state._payableDisplay_ !== undefined && this.state._payableDisplay_ !== '' ? this.state._payableDisplay_.replace(/[^0-9.]/g, "") : _payableAmountDisplayable_
		return <div>
			<Label size="sm"> Payable Amount <br />
			<InputGroup>
				<Input className="m-1" type="text" name="_payableDisplay_" placeholder="Payable Amount" value={ _payableDisplay_ } onChange={this.handlePayableChange} />
				<InputGroupAddon addonType="append">{ BlockTxns.currSymbol(this.context.Settings.curr, { height: '40', className: "pt-2" }) }</InputGroupAddon>
			</InputGroup>
			</Label>
			{ " " + this.context.Settings.curr }
			<StacksEstPrice price={ _payableAmount_ } peg={ "0" } />
		</div>
	}

	renderAccountDetails(connectionMode, purdyNetworkName, account, accountBalance) {
		return (
			<div className="p-1 m-1 small nowrap">
				{ connectionMode } Wallet on the { purdyNetworkName } Network
				<br />
				{ account }
				<br />
				Balance <StacksPrice price={ accountBalance } />
				<StacksEstPrice price={ accountBalance } peg={ "0" } /> USD
			</div> 
		)
	}

	render() {
		const M = BlockTxns.getMethodAbi(this, this.state.contract, this.state.method)
		if (!M) return (<div><Spinner color="primary" /></div>)
		const accountBalance = BlockTxns.getCacheAccountBalance(this, this.context.account)
		const connectionMode = localStorage.getItem('connectionMode')

		return (
		<Container>
			<Row>
			<Col style={{'width': '50%', 'minWidth': '20rem', 'maxWidth': '80rem', 'float': 'left' }}>
				{
					M.inputs.map( (inp, i) => {
						// OUTPUT THE INPUT FIELDS
						return this.renderInput(inp, i);
					})
				}
				{
					M.payable ? ( this.renderPayable() ) : ""
				}
				{ this.renderAccountDetails(connectionMode, this.context.Settings.purdyNetworkName, this.context.account, accountBalance) }
				<div className="mr-3">
						{ BlockTxns.getTxStatus(this, "StacksFormKey", this.state.processingFlag ? 
							<div>
								{/* " Lucky?!... " */}
								<StrangeDice /> 
								<p className="xs text-muted mt-1 mb-1 pt-1 pb-1"> 
									Your transaction has been submitted.
									<br /> 
									<em> Notice, re-submitting may result in duplicate transactions. </em> 
								</p>
								<div>
									<Button className="mt-1 mb-1" type="submit" color="secondary" onClick={this.handleClearProcessing}>Ok</Button>
								</div>
							</div>
						: 

							<div>
								{/* this.renderAccountDetails(connectionMode, this.context.account, accountBalance) */}
								{ !M.payable || this.state.acceptsPayable ?
									<Button className="mt-1 mb-1" type="submit" color="primary" onClick={this.handleSubmit}>{BlockTxns.purdyCamel(this.state.method)}</Button>
								:
									<p className="xs text-muted mt-1 mb-1 pt-1 pb-1 pl-4"> 
										<Input className="mt-1 mb-1" type="checkbox" onClick={this.handleAcceptsPayable} /> 
										I Agree to the <a href="https://www.cryptocomics.com/dyna/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
										&nbsp;
										<em>
											additionally I understand this is a "Payable Transaction"; Whereas all CryptoComics&trade; blockchain transactions are non-refundable, final, and between the buyer and the seller.
										</em>
									</p>
								}
							</div>
						, (txnResult) => { // onSuccess
							return <div>
								<Alert className="mb-0" color="light">
									<span>&#x27A3;&nbsp;</span> Success!
								</Alert>
								<Button className="m-1" type="submit" color="success" onClick={this.handleSubmit}> {BlockTxns.purdyCamel(this.state.method)}, Again? </Button>
								{ this.state.method === 'buy' || this.state.method === 'buyNew' ?
									<>
										<Button className="m-1" href={ "./collection?owner="+this.context.account }> View Collection </Button>
										{/* 
											<Button className="m-1"> View Content </Button>
										*/}
									</>
								: "" }
							</div>
						}
						, (txnResult) => { // onFail
							return <div>
								<Alert className="mb-0" color="light">
									<span>&#x27A3;&nbsp;</span> The Transaction Failed.
								</Alert>
								{/* 
								<div>{JSON.stringify(txnResult)}</div>
								*/}
								<Button className="mt-1 mb-1" type="submit" color="warning" onClick={this.handleSubmit}> Try again? {BlockTxns.purdyCamel(this.state.method)} </Button>
							</div>

						})
						}
				</div>
			</Col>
			{/* 
			<div style={{ 'width': '50%', 'float': 'right' }}>
			*/}
			<Col className="mt-2 ml-2">
				{ Number(this.state.fields.listingId) > 0 ? 
				<div>
					<p className="text-muted pb-0 mb-0 pl-2 pt-6">Listing Id &nbsp; {Number(this.state.fields.listingId)}</p> 
					<StacksListingToken listingId={Number(this.state.fields.listingId)} /> 
				</div>
				: Number(this.state.fields.tokenId) > 0 ? 
					<div>
						<p className="text-muted pb-0 mb-0 pl-2 pt-6">Token Id {Number(this.state.fields.tokenId)}</p> 
						<StacksToken tokenId={Number(this.state.fields.tokenId)} /> 
					</div>
					: ""
				}
			</Col>
			</Row>
			{ this.context.debugger ? 
				<Row> {JSON.stringify(this.state)} </Row>
				: ""
			}
		</Container> )
	}
}

export default StacksForm
