// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown, Card, Row, Col, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, CardFooter } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
import StrangeDice from "../utility/StrangeDice.js"
// import SwissArmy from "../utility/SwissArmy.js"
// import StacksLib from "../stacks/StacksLib.js"
import StacksEstPrice from "../stacks/StacksEstPrice.js"

class StacksToken extends React.Component {
	state = { tokenId: this.props.tokenId, contractId: 1 }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (Number(this.state.tokenId) < 1) return
		BlockTxns.contractCacheCall(this, "Stacks721", "getTokenData", { tokenId: this.state.tokenId }, "getTokenData_1")
		BlockTxns.contractCacheCall(this, "Stacks721", "tokenURI", { tokenId: this.state.tokenId }, "tokenURI_1")
	}
	render() {
		const tokenData = BlockTxns.getCacheValue(this, "Stacks721", "getTokenData", "getTokenData_1", { seriesId: 0 })
		const tokenURI = BlockTxns.getCacheValue(this, "Stacks721", "tokenURI", "tokenURI_1", "")
		return <StacksToken2 tokenId={this.state.tokenId} contractId={this.state.contractId} tokenData={tokenData} tokenURI={tokenURI} />
	}
}

class StacksToken2 extends React.Component {
	state = { tokenId: this.props.tokenId, contractId: this.props.contractId, tokenData: this.props.tokenData, tokenURI: this.props.tokenURI }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		if (Number(this.state.tokenId) < 1) return
		BlockTxns.contractCacheCall(this, "MarketLogic", "getContractListingId", { contractId: this.state.contractId, idType: "token",  id: this.state.tokenId }, "getContractListingId_token")
		BlockTxns.contractCacheCall(this, "MarketLogic", "getContractListingId", { contractId: this.state.contractId, idType: "series",  id: this.state.tokenData.seriesId }, "getContractListingId_series")
	}

	navToggle = () => {
		this.setIsOpen(!this.state.isOpen);
	}

	setIsOpen = (flg) => {
		this.setState({isOpen:flg});
	}

	render() {
		const listingId = BlockTxns.getCacheValue(this, "MarketLogic", "getContractListingId", "getContractListingId_token", 0)
		const seriesListingId = BlockTxns.getCacheValue(this, "MarketLogic", "getContractListingId", "getContractListingId_series", 0)
		return <StacksToken3 tokenId={this.state.tokenId} contractId={this.state.contractId} tokenData={this.state.tokenData} tokenURI={this.state.tokenURI} listingId={listingId} seriesListingId={seriesListingId} />
	}
}

class StacksToken3 extends React.Component {
	state = { tokenId: this.props.tokenId, contractId: this.props.contractId, tokenData: this.props.tokenData, tokenURI: this.props.tokenURI, listingId: this.props.listingId, seriesListingId: this.props.seriesListingId, tokenCdnData: {}, isOpen: false }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		if (Number(this.state.tokenId) < 1) return
		console.log("StacksToken::queryContracts() seriesId =>", this.state.tokenData.seriesId) // #debugger
		BlockTxns.contractCacheCall(this, "Stacks721", "ownerOf", { tokenId: this.state.tokenId }, "ownerOf_1")
		BlockTxns.contractCacheCall(this, "Stacks721", "getSeries", { seriesId: this.state.tokenData.seriesId }, "getSeries_1")
		// BlockTxns.contractCacheCallByApi(this, "Stacks721", "getSeries", { seriesId: this.state.tokenData.seriesId }, "getSeries_1")
		BlockTxns.contractCacheCall(this, "MarketLogic", "getListing", { listingId: this.state.listingId }, "getListing_1")
		BlockTxns.contractCacheCall(this, "MarketLogic", "getListing", { listingId: this.state.seriesListingId }, "getListing_series")
		this.loadTokenJson()
	}

	loadTokenJson = () => {
		try {
			const tokenURI = this.state.tokenURI
			if (this.state.tokenURI !== "") {
				fetch(tokenURI)
				.then((resp) => resp.json())
				.then((respJson) => {
					this.setState({ tokenCdnData: respJson })
				}).catch(error => {
					throw(error)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	navToggle = () => {
		this.setIsOpen(!this.state.isOpen)
	}

	setIsOpen = (flg) => {
		this.setState({isOpen:flg})
	}

	render() {
		const drizzleState = this.context.drizzleState
		// const w3u = this.context.drizzle.web3.utils
		const tokenOwner = BlockTxns.getCacheValue(this, "Stacks721", "ownerOf", "ownerOf_1", "")
		const tokenData = this.state.tokenData
		const seriesData = BlockTxns.getCacheValue(this, "Stacks721", "getSeries", "getSeries_1", {})
		// const seriesData = BlockTxns.getCacheValueByApi(this, "Stacks721", "getSeries", "getSeries_1", {})
		const listing = BlockTxns.getCacheValue(this, "MarketLogic", "getListing", "getListing_1", { price: 0, peg: 0, nativePrice: 0 })
		const seriesListing = BlockTxns.getCacheValue(this, "MarketLogic", "getListing", "getListing_series", { price: 0, peg: 0, nativePrice: 0 })
		const isOwner = (drizzleState.accounts[0] === tokenOwner ? true : false)

		listing.price = ( listing.price !== undefined ? listing.price : 0 )
		listing.peg = ( listing.peg !== undefined ? listing.peg : 0 )
		listing.nativePrice = ( listing.nativePrice !== undefined ? listing.nativePrice : 0 )
		seriesListing.price = ( seriesListing.price !== undefined ? seriesListing.price : 0 )
		seriesListing.peg = ( seriesListing.peg !== undefined ? seriesListing.peg : 0 )
		seriesListing.nativePrice = ( seriesListing.nativePrice !== undefined ? seriesListing.nativePrice : 0 )

		const tokenCdnData = this.state.tokenCdnData
		var issueCoverUrl = "";
		var softcopyLink = "";
		var issueTitle = "";
		var creatorProfileLink = "";
		var creatorUsername = "";
		var seriesTitle = "";
		if (tokenCdnData.id+"" === this.state.tokenId+"") {
			issueCoverUrl = tokenCdnData.image;
			softcopyLink = tokenCdnData.external_link;
			issueTitle = tokenCdnData.issue_title;
			creatorProfileLink = tokenCdnData.creator_link;
			creatorUsername = tokenCdnData.creator_name;
			seriesTitle = tokenCdnData.series_title;
		}
		const tokenDetailsLink =  "./token?tokenId=" + Number(this.state.tokenId) 

		const buyNewLink = "./buynew?listingId=" + seriesListing.listingId + "&_payableAmount_=" + seriesListing.nativePrice + "&to=" + drizzleState.accounts[0]
		const buyLink = "./buy?listingId=" + listing.listingId + "&_payableAmount_=" + listing.nativePrice + "&to=" + drizzleState.accounts[0]

		const oMenu = (
			<ButtonDropdown direction="left" isOpen={this.state.isOpen} toggle={this.navToggle}>
				{/* 
			<DropdownToggle size="sm" caret>
				*/}
			<DropdownToggle size="sm" color="muted" className="pt-0 pr-t mt-0 mr-0">
				&nbsp;
				{/* 
				<span>&#9881;</span>&nbsp;
				*/}
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem href={tokenDetailsLink} >
					<div> {seriesData.symbol} </div>
					<span>&#8470;</span> {tokenData.number} of {seriesData.issued}
					{ ( Number(seriesData.limit) > 0 ? <div>Limited {seriesData.limit}</div> : "" ) }
				</DropdownItem>

				<DropdownItem divider />

				<DropdownItem href={softcopyLink} target="" rel="noopener noreferrer" className="text-muted">
					View Content
					&nbsp;
					<span role="img" aria-label="link">&#128279;</span>
				</DropdownItem>

				{/* 
				<DropdownItem href={"./mint?seriesId=" + tokenData.seriesId + "&_payableAmount_=" + stacksPrice + "&to=" + drizzleState.accounts[0]}>
				*/}

				{ isOwner ? <>
					<DropdownItem href={ "./forms?contract=SalesLogic&method=setSale&listingId="+ this.state.listingId +"&contractId="+ this.state.contractId +"&owner="+ drizzleState.accounts[0] +"&tokenId=" + Number(this.state.tokenId) + "&price=" + listing.price + "&peg=" + listing.peg }>Set Sale</DropdownItem>
					{/* 
						<DropdownItem href={"./viewer?viewerAccount=" + drizzleState.accounts[0] + "&tokenId=" + Number(this.state.tokenId)}>Set Viewer</DropdownItem>
						<DropdownItem href={"./dynamic?contract=TradeLogic&method=offerTrade&tokenId=" + Number(this.state.tokenId) + "&trader=" + drizzleState.accounts[0]}>Offer For Trade</DropdownItem>
						<DropdownItem href={"./dynamic?contract=MediaLogicExt&method=postAuction&tokenId=" + Number(this.state.tokenId) + "&seller=" + drizzleState.accounts[0]}>Post For Auction</DropdownItem>
					*/}
				</> : <>
					{( listing.nativePrice > 0 ? 
						<DropdownItem href={buyLink}>
							Buy This
						</DropdownItem> 
					: "")}

					{/*
					{( listing.nativePrice > 0 ? 
						<DropdownItem href={"./forms?contract=SalesLogic&method=buy&listingId="+ this.state.listingId +"&tokenId=" + this.state.tokenId + "&_payableAmount_=" + listing.nativePrice + "&to=" + drizzleState.accounts[0]}>
							Buy This
						</DropdownItem> 
					: "")}
					...........
					<DropdownItem href={"./dynamic?contract=MediaLogicExt&method=postBid&tokenId=" + Number(this.state.tokenId) + "&bidder=" + drizzleState.accounts[0] + "&number=" + Number(tokenData.number)}>
						Post Bid
					</DropdownItem>
					.................
					<DropdownItem href={"./forms?contract=SalesLogic&method=buyNew&listingId="+ this.state.seriesListingId +"&seriesId=" + tokenData.seriesId + "&_payableAmount_=" + seriesListing.nativePrice + "&to=" + drizzleState.accounts[0]}>
						Buy New
					</DropdownItem>
					*/}

				</> 
				}

				<DropdownItem href={buyNewLink}>
					Buy New
				</DropdownItem>
			</DropdownMenu>
			</ButtonDropdown>
		)
/*
							<CardText className="ml-2 overflow-hidden">
								<span className="text-bold" onClick={this.navToggle}>
											<span role="img" aria-label="Proof">&#128215;</span>
											{tokenData.number}
								</span>
							</CardText>
*/
		return (
			<Card className="m-1" style={{width: '16rem', minWidth: '16rem', maxWidth: '16rem'}}>
				<CardHeader className="m-1 p-1" onClick={this.navToggle} style={{ cursor: 'pointer' }}>
					<Row className="flex-nowrap">
						<Col className="col-auto" style={{ width: '15rem' }}>
							<div className="ml-2 overflow-hidden" style={{ textAlign: 'center' }}>
								{seriesData.symbol} 
								&nbsp;|&nbsp;
								{tokenData.number > 0 ? 
									<span>
										<span>&#8470;</span>
										{tokenData.number}
										{" "}
										{ BlockTxns.currSymbol(this.context.Settings.curr, {}) }
									</span> 
								:
									<span>
										<StrangeDice diceDo={{}} />
									</span>
								}
								{ isOwner ? <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span> : '' }
							</div>
						</Col>
						<Col>
							{oMenu}
						</Col>
					</Row>
				</CardHeader>

				{/* 
				<CardImg top width="100%" src={issueCoverUrl} alt={issueTitle} onClick={this.navToggle} style={{ cursor: 'pointer' }} />
				*/}
				<a href={tokenDetailsLink}>
					<CardImg top width="100%" src={issueCoverUrl} alt={issueTitle} />
				</a>

					{/* 
				<div className="text-center p-1 m-0 text-muted">
					<a href={softcopyLink} target="" rel="noopener noreferrer" className="text-muted">
						View Unlockable Content
						&nbsp;
						<span role="img" aria-label="link">&#128279;</span>
					</a>
				</div>
					*/}
				<CardBody className="pt-1 mt=0">
					<CardTitle tag="h6">{issueTitle} <a href={creatorProfileLink} target="" rel="noopener noreferrer">{creatorUsername}</a></CardTitle>
					<CardSubtitle tag="h6" className="mb-2 text-muted">{seriesTitle}</CardSubtitle>
				</CardBody>

				<div className="text-center p-1 m-0 text-muted">
					{seriesData.issued}
					{ seriesData.limit > 0 ? " of " : "" }
					{seriesData.limit}
				</div>

				<CardFooter>
					<CardText tag="small" className="text-muted">
						<Row><Col align="right" className="col-4 text-nowrap">Token Id</Col><Col>{this.state.tokenId}</Col></Row>
						<Row className="text-truncate">
							<Col align="right" className="col-4">Owner</Col>
							<Col className="text-truncate">
									{ isOwner ?
										<a href={"./token?tokenId=" + Number(this.state.tokenId)}>Your Token<span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span></a>
										//<a href={"./collection?owner="+copyData.owner}>Your Token<span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span></a>
									:
										<a href={"./listings?owner=" + tokenOwner}>{tokenOwner}</a>
									}
							</Col>
						</Row>
						<Row>
							<Col align="right" className="col-4">Asking</Col>
							<Col className="text-truncate">
								{/* this.state.listingId+"" === "0" ? "Unlisted" : BlockTxns.currDraw(this, displayPrice) */}
								{ this.state.listingId+"" === "0" ? "Unlisted" : BlockTxns.currDraw(this, listing.nativePrice) }
							</Col>
						</Row>
						<Row>
							<Col align="right" className="col-4 text-nowrap"> { "Est. Price" } </Col>
							<Col className="text-truncate">
								<StacksEstPrice price={ listing.price } peg={ listing.peg } />
							</Col>
						</Row>
						{/* 
						<Row>
							<Col align="right" className="col-4 text-nowrap"> { "Limit" } </Col>
							<Col className="text-truncate text-center">
								{seriesData.issued} 
								{ " / " }
								{seriesData.limit}
							</Col>
						</Row>
						*/}
					</CardText>
				</CardFooter>
				{/* 
				<div>
					{JSON.stringify(this.state.listingId)}
				</div>
				<div>
					{JSON.stringify(this.state)}
				</div>
				<div>
					{JSON.stringify(tokenData)}
				</div>
				<div>
					{JSON.stringify(seriesData)}
				</div>
				*/}
			</Card>
		)
	}
}

export default StacksToken
