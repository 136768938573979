// StacksSeries.js - 20220621 j0zf at apogeeinvent.com
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
import StacksSeries from '../stacks/StacksSeries'

class StacksSeriesById extends React.Component {
	state = { seriesId: this.props.seriesId, contractId: this.props.contractId }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (!this.state.contractId || !this.state.seriesId) return;
		BlockTxns.contractCacheCall(this, "MarketLogic", "getContractListingId", { contractId: this.state.contractId, idType: "series", id: this.state.seriesId }, "getContractListingId_1")
	}
	render() {
		const listingId = Number(BlockTxns.getCacheValue(this, "MarketLogic", "getContractListingId", "getContractListingId_1", 0))
		return <StacksSeriesById2 listingId={listingId} />
	}
}

class StacksSeriesById2 extends React.Component {
	state = { listingId: this.props.listingId }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (!this.state.listingId) return;
		BlockTxns.contractCacheCall(this, "MarketLogic", "getListing", { listingId: this.state.listingId }, "getListing_1")
	}
	render() {
		const listing = BlockTxns.getCacheValue(this, "MarketLogic", "getListing", "getListing_1", {})

		//return <div>StacksSeriesById:{JSON.stringify(this.state)}<StacksSeries listing={listing} /></div>
		return <StacksSeries listing={listing} />
	}
}


export default StacksSeriesById
