// loadSettingsReact.js 2022 0901 j0zf

const settings = require('./settings.json')
const loadSettings = (networkName) => {
	if (networkName === undefined || networkName === '') {
		var hosty = window.location.hostname.split(".").reverse()
		if (hosty[2] !== undefined) {
			networkName = hosty[2]
		} else {
			networkName = settings.networkName
		}
	}
	networkName = networkName.toLowerCase().trim()
	const Settings = { ...settings, ...settings[networkName], networkName:networkName  }
	//console.log("Loaded Settings => networkName => " + networkName)
	return Settings
}

export default loadSettings
