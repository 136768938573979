// StacksEstPrice.js 2022 0717 by j0zf at apogeeinvent.com
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
// import { DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown, Card, Row, Col, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, CardFooter } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
// import StacksLib from "../stacks/StacksLib.js"

class StacksEstPrice extends React.Component {
	state = { price: this.props.price, peg: this.props.peg }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "AccountingLogic", "getConversion", { peg: "1", value: "1" }, "getConversion_1")
	}
	render() {
		if (!this.context.drizzle || !this.context.drizzle.web3 || !this.context.drizzle.web3.utils) return <span></span>

 		const w3u = this.context.drizzle.web3.utils
		const multiplier = BlockTxns.getCacheValue(this, "AccountingLogic", "getConversion", "getConversion_1", "1")
		var calcPrice = '0'

		try {
			switch (this.state.peg) {
				case '1': calcPrice = (parseInt(this.state.price) / 100).toFixed(2); break
				default: calcPrice = (parseInt(new w3u.BN(this.state.price).div(new w3u.BN(multiplier))) / 100).toFixed(2)
			}
		} catch (err) {
			calcPrice = '-.--'
		}

		return <span>  ~ ${ calcPrice.toString() } </span>
		//return <span> State: { JSON.stringify(this.state)} | *= { multiplier } | calc: { calcPrice.toString() } | ~ ${ calcPrice.toString() } </span>

	}
}

export default StacksEstPrice
