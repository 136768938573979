// StacksData.js 20210509 j0zf at ApogeeINVENT
// StacksForm.js 20210414 j0zf at ApogeeINVENT
// StacksPublish.js 20210411 j0zf at ApogeeINVENT

import React from "react"
import { Spinner, Input, Container, Label } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import StacksToken from './StacksToken.js'
import StacksDataList from './StacksDataList.js'
import BlockTxns from '../utility/BlockTxns'

class StacksData extends React.Component {
	state = { contract: this.props.contract, method: this.props.method, fields: {}, tokenPane: false, queryObject: {} }
	static contextType = DrizzleContext.Context

	constructor (props) {
		super(props)
		this.state.tokenPane = ( props.tokenPane && (props.tokenPane===true || props.tokenPane==='true') ? true : false ); // include the token pane? binds with this.state.fields.tokenId
		if (this.props.queryObject) {
			this.state.queryObject = this.props.queryObject;
			for (var qI in this.props.queryObject) {
				this.state.fields[qI] = this.props.queryObject[qI]
			}
		}
	}

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if ( (this.props.contract !== prevProps.contract)
		|| (this.props.method !== prevProps.method) ) {
			this.setState({contract: this.props.contract, method: this.props.method}, this.queryContracts)
		}
	}

	queryContracts = () => {
		BlockTxns.contractCacheCall(this, this.props.contract, this.props.method, this.state.fields, "QueryResult");
		// console.log("StacksData::queryContracts() -> BlockTxns.contractCacheCallByApi()")
		// BlockTxns.contractCacheCallByApi(this, this.props.contract, this.props.method, this.state.fields, "ApiResult");
	}

	getFieldState = (fname) => {
		return (this.state.fields[fname] === undefined ? ( this.state.queryObject[fname] === undefined ? "" : this.state.queryObject[fname]) : this.state.fields[fname]);
	}

	handleFieldChange = (e) => {
		var fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields }, this.queryContracts())
	}

	render() {
		const queryResult = BlockTxns.getCacheValue(this, this.props.contract, this.props.method, "QueryResult", {status:"..."})
		// const apiResult = BlockTxns.getCacheValueByApi(this, this.props.contract, this.props.method, "ApiResult", {status:"..."})
		const M = BlockTxns.getMethodAbi(this, this.state.contract, this.state.method)
		if (!M) return (<div><Spinner color="primary" /></div>)

		return ( <div>
			<div style={{'width': '50%', 'minWidth': '20rem', 'maxWidth': '40rem', 'float': 'left' }}>
				{
					M.inputs.map( (inp, i) => {
						return (<div key={"y87y7y87afdsy"+i}>
							<Label key={"f8dfsa87"+i} size="sm" className="pt-0 m-0"> {BlockTxns.purdyCamel(inp.name)} 
							<Input className="m-1" key={"7sf878df9s"+i} type="text" name={inp.name} placeholder={BlockTxns.purdyCamel(inp.name)} value={this.getFieldState(inp.name)} onChange={this.handleFieldChange} />
							</Label>
						</div>);
					})
				}
				{
					M.payable ? (<div>
							<Label size="sm"> Payable Amount <br />
							<Input className="m-1" type="text" name="_payableAmount_" placeholder="Payable Amount" value={this.getFieldState("_payableAmount_")} onChange={this.handleFieldChange} />
							</Label>
						</div>) : ""
				}
				<div>
					<Container className="mt-3">
						<StacksDataList fields={queryResult} />
					</Container>
				</div>
			</div>
			<div style={{'width': '20rem', 'float': 'left'}}>
				{ (this.state.tokenPane ? <StacksToken tokenId={Number(this.getFieldState('tokenId'))} /> : "") }
			</div>
			<div className="clearfix"></div>
			{/* 
			<div>QueryResult: { JSON.stringify(apiResult) }</div>
			<div>ApiResult: { JSON.stringify(apiResult) }</div>
			*/}
		</div> )
	}
}

export default StacksData
