// InfoPane.js - 2021-04-10 j0zf

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin';
import { Container } from "reactstrap";

import { newContextComponents } from "@drizzle/react-components";
const { AccountData, ContractData } = newContextComponents;

class InfoPane extends React.Component {
	state = {};
	static contextType = DrizzleContext.Context;

	render() {
        let drizzle = this.context.drizzle;
        let drizzleState = this.context.drizzleState;

		return (
        <Container>
            <div>
                <h3>Active Account</h3>
                <AccountData drizzle={drizzle} drizzleState={drizzleState} accountIndex={0} units="ether" precision={3} />
                <h4>Account ViewerId</h4>
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="MediaLogicView" method="getViewerId" methodArgs={[drizzleState.accounts[0]]} />
            </div>
            <div>
                <h3>Roles</h3>
                {" Admin: "}
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="AppLogic" method="hasRole" methodArgs={[1, drizzleState.accounts[0]]} />
                <br />                                                                                                        
                {" Manager: "}                                                                                                
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="AppLogic" method="hasRole" methodArgs={[2, drizzleState.accounts[0]]} />
                <br />
                {" Publisher: "}
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="AppLogic" method="hasRole" methodArgs={[3, drizzleState.accounts[0]]} />
                <br />
            </div>
            <div>
                <h3>ERC721</h3>
                {" Name: "}
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="ERC721Logic" method="name" />
                <br />
                {" Symbol: "}
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="ERC721Logic" method="symbol" />
                <br />
                {" Total Supply: "}
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="ERC721Logic" method="totalSupply" />
                <br />
                {" Balance: "}
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="ERC721Logic" method="balanceOf" methodArgs={[drizzleState.accounts[0]]} />
                <br />
                {" Base URI: "}
                <ContractData drizzle={drizzle} drizzleState={drizzleState} contract="ERC721Logic" method="baseURI" />
                <br />
            </div>
        </Container>
		);
	}
}

export default InfoPane;


