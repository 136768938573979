// TokensPane.js 20210502 j0zf at ApogeeINVENT
// CollectionPane.js 20210424 j0zf at ApogeeINVENT

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import { Container } from "reactstrap"
import equal from "fast-deep-equal"
import StacksTokens from "../stacks/StacksTokens"

class TokensPane extends React.Component {
	state = { queryObject: this.props.queryObject }
	static contextType = DrizzleContext.Context

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	render() {
		const drizzleState = this.context.drizzleState
		return <Container>
			<h2> All Tokens {"  -  "} Stacks721&trade; Media Tokens </h2>
			<p className="sm"> <a href="./listings"> All Listings </a> | <a className="curPageLink" href="./tokens"> All Tokens </a> | <a href={"./listings?owner=" + drizzleState.accounts[0] }> My Listings </a> | <a href={"./collection?owner=" + drizzleState.accounts[0] }> My Collection </a> </p>
			<StacksTokens owner={ this.state.queryObject.owner } page={ this.state.queryObject.page } />
		</Container>
	}
}

export default TokensPane;
