// StrangeDice.js 20210415 j0zf at ApogeeINVENT
// Handy Reference Site http://www.amp-what.com

import React from "react";
class StrangeDice extends React.Component {
    state = { diceDo: {
        "2": <span role="img" aria-label="2">&#x1f576;</span>,
        "3": <span role="img" aria-label="3">&#x266A;&#x263D;&#x266C;</span>,
        "4": <span role="img" aria-label="4">&#127808;&#127808;&#127808;&#127808;</span>,
        "5": <span role="img" aria-label="5">&#9995;</span>,
        "6": <span role="img" aria-label="6">&#127769;</span>,
        "7": <span role="img" aria-label="7">&#x21;&#x1f305;&#x1f33b;&#127808;&infin;&#x1f312;&#x1f576;</span>,
        "9": <span role="img" aria-label="9">&#127913;&#129418;&#127762;&#127768;</span>,
        "12": <span role="img" aria-label="12">&#128642;&#128643;&#128643;&#128643;&#128643;&#128643;&#128643;</span>
        },
        die1: 0,
        die2: 5,
        rolls: 0,
        rerolls: 1000 // miliseconds timeout
    };

    rollTimer = null;

	constructor (props) {
        super(props);
        if (this.props.diceDo) {
            this.state.diceDo = this.props.diceDo;
        }
        if (this.props.rerolls !== undefined) {
            this.state.rerolls = this.props.rerolls;
        }
    }
    
    roll = () => {
        const rolls = this.state.rolls + 1;
        const die1 = Math.floor(Math.random() * 6);
        const die2 = Math.floor(Math.random() * 6);
        this.setState({die1, die2, rolls})
        if (this.state.rerolls > 0) {
            this.rollTimer = setTimeout(this.roll, this.state.rerolls);
        }
    }

    componentDidMount() {
        this.roll();
    }

    componentWillUnmount() {
        clearTimeout(this.rollTimer);
    }

    render() {
        const dice = [<span>&#9856;</span>, <span>&#9857;</span>, <span>&#9858;</span>, <span>&#9859;</span>, <span>&#9860;</span>, <span>&#9861;</span>];
        const die1 = this.state.die1;
        const die2 = this.state.die2;
        const diceSum = die1 + die2 + 2;
        const diceDo = this.state.diceDo;
        return (<span>
            &nbsp;
            { dice[die1] } 
            { dice[die2] }
            &nbsp;
            { diceDo[diceSum+""] }
        </span>);
    }
}

export default StrangeDice;
