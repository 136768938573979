// CollectionPane.js 20210424 j0zf at ApogeeINVENT

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import { Container } from "reactstrap"
import equal from "fast-deep-equal"
import StacksOwnerTokens from "../stacks/StacksOwnerTokens"

class CollectionPane extends React.Component {
	state = { queryObject: this.props.queryObject }
	static contextType = DrizzleContext.Context

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	render() {
        //if (!this.context.drizzle.web3 || !this.context.drizzle.web3.utils) return <div></div>

		const drizzleState = this.context.drizzleState
        const w3u = this.context.drizzle.web3.utils
		const isOwner = this.state.queryObject.owner && this.state.queryObject.owner === drizzleState.accounts[0]
		return <Container>
			<h2> 
			{ this.state.queryObject.owner ?  
				( isOwner ?  
					"My Collection"
					: "Their Collection"
				) : "All Tokens"
			}
			{"  -  "} Stacks721&trade; Media Tokens
			</h2>
			<p className="xs">
					{ w3u.isAddress(this.state.queryObject.owner) && this.state.queryObject.owner !== drizzleState.accounts[0] ? 
						<div>
							{ "Owner " + this.state.queryObject.owner + " | " }
							{this.context.debugger ? 
								<span>
									<a className="xs" href={"./holdings?account=" + this.state.queryObject.owner}> Hodl </a>
									{" | "}
								</span>
								: ""
							}
							<a className="xs" href={"./listings?owner=" + this.state.queryObject.owner}> Their Listings </a>
						</div>
					: "" }
			</p>
			<p className="sm"> <a href="./listings"> All Listings </a> | <a href="./tokens"> All Tokens </a> | <a href={"./listings?owner=" + drizzleState.accounts[0] }> My Listings </a> | <a className={isOwner?'curPageLink':''} href={"./collection?owner=" + drizzleState.accounts[0] }> My Collection </a> </p>
			<StacksOwnerTokens owner={this.state.queryObject.owner} page={this.state.queryObject.page} />
		</Container>
	}

}

export default CollectionPane
