// StacksOwnerToken.js - 20210425 j0zf at ApogeeINVENT
// StacksOwnerTokens.js - 20210424 j0zf at ApogeeINVENT
// StacksToken.js - 20210415 j0zf at ApogeeINVENT
// StacksForm.js - 20210414 j0zf at ApogeeINVENT
// StacksPublish.js - 20210411 j0zf at ApogeeINVENT

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import StacksToken from "../stacks/StacksToken"
import BlockTxns from "../utility/BlockTxns.js"

class StacksOwnerToken extends React.Component {
	state = { owner: this.props.owner, index: this.props.index }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "Stacks721", "tokenOfOwnerByIndex", { 'owner': this.state.owner, 'index': this.state.index }, "tokenOfOwnerByIndex_result")
	}

	refresh = () => {
		this.setState({refresh: this.state.refresh+1})
	}

	render() {
		const tokenId = Number(BlockTxns.getCacheValue( this, "Stacks721", "tokenOfOwnerByIndex", "tokenOfOwnerByIndex_result", 0))
		return <StacksToken tokenId={tokenId} />
	}
}

export default StacksOwnerToken
