// drizzleOptions.js j0zf 2021
import Web3 from "web3"
import HDWalletProvider from "@truffle/hdwallet-provider"
import WalletConnectProvider from "@walletconnect/web3-provider"

import Stacks721 from "./blockchain/ABIs/Stacks721.json"
import StacksStore from "./blockchain/ABIs/StacksStore.json"
import AppLogic from "./blockchain/ABIs/AppLogic.json"
import AccountingLogic from "./blockchain/ABIs/AccountingLogic.json"
import MarketLogic from "./blockchain/ABIs/MarketLogic.json"
import SalesLogic from "./blockchain/ABIs/SalesLogic.json"

import loadSettings from './blockchain/loadSettingsReact.js'
const Settings = loadSettings('')
const contracts = [ Stacks721, StacksStore, AppLogic, AccountingLogic, MarketLogic, SalesLogic ]

const drizzleOptions = {
	connectionSuccess: false,
	Settings: Settings,
	web3: {
		block: false,
		customProvider: null,
		fallback: null, // #eg { type: "ws", url: Settings.BlockchainUrl },
		pollingInterval: Settings.pollingInterval
	},
	syncAlways: true,
	contracts: contracts,
	events: {
		// if there is an issue with the event (like non-existent) the whole app crashes. so look here if that is happening.

		// #tempdisable Stacks721: ["DepositReceived", "Withdrawn", "SeriesSet", "RoleSetup", "RoleSet", "ValuesSet", "NumbersSet", "Transfer", "Approval", "ApprovalForAll"],
		// #tempdisable AccountingLogic: ["HoldingsWithdrawn", "AccountCredited", "AccountDebited", "PoolCredited", "PoolDebited"],
		// #tempdisable AppLogic: ["PausedSet", "RoleSet"],
		// #tempdisable MarketLogic: ["ListingCanceled", "TokenContractSet"],
		// #tempdisable SalesLogic: ["MintableSet", "SaleSet", "BoughtNew", "Bought"],
	},
	pollingInterval: Settings.pollingInterval,
	disableConfirmationListener: true // ??
}

const setupDrizzleOptions = async () => {
	var cons = {} // gonna need to lookup our contracts by name so..
	for (var coni in contracts) {
		cons[contracts[coni].contractName] = contracts[coni]
	}

	var wiring = Settings.contractWiring
	for (var ww in wiring) {
		var wire = wiring[ww]
		for (var nn in cons[wire.name].networks) {
			if (cons[wire.name].networks[nn] && cons[wire.name].networks[nn].address && cons[wire.at].networks[nn] && cons[wire.at].networks[nn].address ) {
				cons[wire.name].networks[nn].address = cons[wire.at].networks[nn].address
			} else {
				console.log("WIRING ERROR setupDrizzleOptions : cons[wire.name=>"+wire.name+"].networks[nn=>"+nn+"].address = cons[wire.at=>"+wire.at+"].networks[nn=>"+nn+"].address")
			}
		}
	}

	const connectionMode = localStorage.getItem('connectionMode')
	var auth_data_json = localStorage.getItem('auth_data')
	switch (connectionMode) {
		case 'Browser': 
			// MetaMask, Brave, etc.
			console.log("MetaMask connectionMode", connectionMode)
			drizzleOptions.web3.customProvider = null
			drizzleOptions.connectionSuccess = true
		break

		case 'WalletConnect': 
			console.log("WalletConnect connectionMode", connectionMode)
			//  Create WalletConnect Provider
			const wcProvider = new WalletConnectProvider({
				//infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
				rpc: { 11612: "https://stacks1.stacksnet.io"},
			})
			const startWCProvider = async () => {
				//  Enable session (triggers QR Code modal)
				wcProvider.enable()
				.then( () => {
					drizzleOptions.connectionSuccess = true
					drizzleOptions.web3.customProvider = wcProvider
					console.log("startWCProvider", wcProvider)
				})
				.catch((err) => {
					drizzleOptions.connectionSuccess = false
					drizzleOptions.web3.customProvider = null
					console.log(err)
				})
				wcProvider.on("connect", (error, payload) => {
					if (error) {
						console.log("WalletConnect error =>", error)
						drizzleOptions.connectionSuccess = false
						drizzleOptions.web3.customProvider = null
					} else {
						drizzleOptions.connectionSuccess = true
					}
					console.log("WalletConnect payload =>", payload)

				})
				wcProvider.on("accountsChanged", (accounts) => {
					console.log(accounts)
				})
				wcProvider.on("chainChanged", (chainId) => {
					console.log(chainId)
				})
				wcProvider.on("disconnect", (code, reason) => {
					console.log(code, reason)
					drizzleOptions.connectionSuccess = false
				})
			}
			startWCProvider()
		break

		case 'CryptoComics':
		default: 
			console.log("CryptoComics connectionMode", connectionMode)
			if (!connectionMode || connectionMode === '') {
				localStorage.setItem('connectionMode', 'CryptoComics')
			}

			if (auth_data_json) {
				var auth_data = JSON.parse(auth_data_json)
				if (auth_data && auth_data.keystore) {
					const hdwProvider = new Web3( new HDWalletProvider({ mnemonic: { phrase: auth_data.keystore.mnemonic }, providerOrUrl: Settings.BlockchainUrl, pollingInterval: Settings.pollingInterval }))
					drizzleOptions.web3.customProvider = hdwProvider
					drizzleOptions.connectionSuccess = true
				} else {
					console.log(' - auth_data : not logged in successfully - ')
					localStorage.clear()
					window.location.reload()
				}
			} else {
				drizzleOptions.connectionSuccess = false
			}		
	}
}

try {
	setupDrizzleOptions()
} catch (err) {
	console.log("EXCEPTION in drizzleOptions.js =>", err)
}

export default drizzleOptions
