// StacksDataList.js 20210512 j0zf at ApogeeINVENT
// StacksTokenDetails.js 2010418 j0zf at ApogeeINVENT
// StacksToken.js 20210415 j0zf at ApogeeINVENT
// StacksForm.js 20210414 j0zf at ApogeeINVENT
// StacksPublish.js 20210411 j0zf at ApogeeINVENT

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns'

class StacksDataList extends React.Component {
	state = { fields: {} }
	static contextType = DrizzleContext.Context

	constructor (props) {
		super(props)
		if (typeof props.fields === 'object') {
			this.state.fields = props.fields
		} else if (typeof props.fields === 'boolean') {
				this.state.fields = (props.fields ? 'True' : 'False')
		} else {
			this.state.fields = {result: props.fields}
		}
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			var fields = this.props.fields;
			if (typeof fields !== 'object') {
				if (typeof fields === 'boolean') {
					fields = (fields ? 'True' : 'False')
				}
				fields = {result: fields}
			}
			this.setState({fields})
		}
	}
	
	handleDataClick = (event) => {
		event.target.classList.remove('text-truncate')
	}

	render() {
		var OO = [], nn = "";

		for (nn in this.state.fields) {
			// FIRST ATTEMPT TO BUILD THE NAME/VALUE PAIRS WITH NAMED FIELDS
			if (isNaN(nn)) {
				OO.push({ name: BlockTxns.purdyCamel(nn), value: this.state.fields[nn] })
			}
		}
		
		if (OO.length < 1) {
			// JUST NUMBERED INDEXES, better than nothing
			for (nn in this.state.fields) {
				OO.push({ name: BlockTxns.purdyCamel(nn), value: this.state.fields[nn] })
			}
		}

		return ( <dl className="row">
			{OO.map((o, i) => {
				return ( <>
					<dt key={"8df7asf"+i} className="col-sm-4 mb-2">{o.name}</dt>
					<dd key={"7dfay87"+i} className="col-sm-8 text-truncate small bg-light" onClick={this.handleDataClick}>{o.value}</dd>
				</> )
			})}
		</dl> )
	}
}

export default StacksDataList;
