// EthAuthPane.js 2022 0701 j0zf

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin'
import { Container } from "reactstrap"
import { Button, ButtonGroup } from "reactstrap"
import { SiweMessage } from 'siwe';
import { ethers } from 'ethers'
import loadSettings from '../blockchain/loadSettingsReact.js'
// import DrizNetworkName from '../utility/DrizNetworkName'
import BlockTxns from '../utility/BlockTxns.js'
// const Settings = loadSettings('')

class EthAuthPane extends React.Component {
	state = { connectionMode: this.props.connectionMode, auth_data: this.props.auth_data, account: this.props.account}
	static contextType = DrizzleContext.Context

	componentDidUpdate(prevProps) {
		if (this.props.connectionMode !== prevProps.connectionMode || this.props.account !== prevProps.account ) {
			this.setState(this.props)
		}
	}

	handleConnectionModeChange = (e) => {
		e.preventDefault()
		const connectionMode = e.target.dataset.connectionmode
		localStorage.setItem('connectionMode', connectionMode)
		window.location.reload()
	}

	handleSiteConnect = async (e) => {
		e.preventDefault()

		try {
			const web3 = this.context.drizzle.web3
			var account = this.state.account
			var nonce = ""

			const message = new SiweMessage({
				domain: document.location.host,
				address: account,
				chainId: await web3.eth.getChainId(),
				uri: document.location.origin,
				version: '1',
				statement: 'Connect as CryptoComics.com User: ' + this.state.auth_data.username,
				user_id: this.state.auth_data.user_id,
				nonce,
			})

			const preparedMessage = message.prepareMessage()
			const provider = new ethers.providers.Web3Provider(web3.currentProvider)
			const signature = await provider.getSigner().signMessage(preparedMessage);
			console.log("message => ", message)
			console.log("preparedMessage => ", preparedMessage)
			console.log("signature => ", signature)

			// #nix fetch(Settings["StacksBlkApi" + Settings.postFix] + '/ethauth', { 
			fetch(this.context.Settings["StacksBlkApi"] + '/ethauth', { 
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({ message, signature })
			}).then(res => {
				return res.json()
			}).then(data => {

				if (data.status && data.status === "success") {
					console.log("SUCCESS data => ", data)
				} else {
					console.log("FAIL data => ", data)
				}
			}).catch(err => {
				console.log("catch error => ", err)
				console.log("handleSiteConnect() Caught Fetch Error", err)
			})
		} catch(err) {
			console.log("handleSiteConnect() Caught Error", err)
		}
	}

	handlePageRefresh = () => {
		window.location.reload()
	}

	render() {
		const selectedNetPurdy = BlockTxns.purdyCamel(this.context.Settings.networkName)
		const connectedNetName = BlockTxns.getConnectedNetwork(this) 
		const connectedNetPurdy = BlockTxns.purdyCamel(connectedNetName) 
		const connectedNetSettings = loadSettings(connectedNetName)
		return <Container>
			<h4>Select Wallet Connection</h4>
			<ButtonGroup size="lg" className="my-2">
				<Button outline color={ this.state.connectionMode === "CryptoComics" ? "primary" : "secondary" } data-connectionmode="CryptoComics" onClick={this.handleConnectionModeChange}> CryptoComics Wallet </Button>
				<Button outline color={ this.state.connectionMode === "Browser" ? "primary" : "secondary" } data-connectionmode="Browser" onClick={this.handleConnectionModeChange}> Browser Wallet - MetaMask, Brave ... </Button>
				{/* 
				<Button outline color={ this.state.connectionMode === "WalletConnect" ? "primary" : "secondary" } data-connectionmode="WalletConnect" onClick={this.handleConnectionModeChange}> WalletConnect </Button>
				*/}
			</ButtonGroup>


			<div className="mt-2">
				{ this.context.initialized ? 
					<div> 
					{ this.context.Settings.BlockchainNetworkId === this.context.drizzleState.web3.networkId ?
						<div> Connected to the <strong>{connectedNetPurdy} Network</strong> { this.context.auth_data.username ? <span>as <em>"{this.context.auth_data.username}"</em></span> : "" } with your <strong>{ this.state.connectionMode} Wallet</strong></div>
					:
						<div> 
							<div>
								{ connectedNetPurdy !== "" ? 
									<span>Connected to <a href={connectedNetSettings.dAppUrl + "details"} >"{connectedNetPurdy} Network"</a> </span> 
									: 
									<span> </span> 
								}  
							</div>

							{ this.state.connectionMode !== 'CryptoComics' ?
								<div> 
									To connect your Browser Wallet (MetaMask, Etc) please select the <em>"{selectedNetPurdy} Network"</em> from your wallet application then <a onClick={this.handlePageRefresh} rel="noopener noreferrer" href="javascript: void(0)">Refresh the Page</a>. 
									<br />
									Is the {selectedNetPurdy} Network not in your Browser Wallet? Add <a href={ "https://chainlist.org/chain/" + this.context.Settings.BlockchainNetworkId } rel="noopener noreferrer" target="_blank">{selectedNetPurdy} at Chainlist.org</a>. 
								</div>
							: 
								<div></div> 
							}
						</div>
					} 
					</div> 
				: <div>Not Initialized</div> 
				}
			</div>


			<div style={{ display: 'none' }}>
				<Button outline onClick={this.handleSiteConnect} size="sm" > Connect to CryptoComics </Button>
			</div>
			{/* 
			<div className="mt-2">{ this.context.initialized ? <div>Initialized</div> : <div>Not Initialized</div> }</div>
			<div> { JSON.stringify(this.state) } </div>
			*/}
		</Container>
	}

}

export default EthAuthPane
