// HomePane.js 20210429 j0zf
// InfoPane.js 20210410 j0zf

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import { Container, Input } from "reactstrap";
import { newContextComponents } from "@drizzle/react-components"
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
import StacksEstPrice from "../stacks/StacksEstPrice.js"
import StacksPrice from "../stacks/StacksPrice.js"
import loadSettings from '../blockchain/loadSettingsReact.js'
//#nix import StacksLib from "../stacks/StacksLib.js"
const { ContractData } = newContextComponents

class HomePane extends React.Component {
	state = {}
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	
	queryContracts = () => {
		if (this.context.initialized) {
			BlockTxns.cacheAccountBalance(this, this.context.account)
		}
	}
	
	handleNetworkSelection = (e) => {
		var S = loadSettings(e.target.value)
		var connectionMode = localStorage.getItem('connectionMode')
		if (S && S.dAppUrl) {
			window.location.replace(S.dAppUrl + "details?connectionMode=" + connectionMode)
		} else {
			console.log("handleNetworkSelection(e) e =>", e)
		}
	}

	render() {
		let drizzle = this.context.drizzle
		let drizzleState = this.context.drizzleState
		const accountBalance = BlockTxns.getCacheAccountBalance(this, this.context.account)
		const activeNetworks = this.context.Settings.activeNetworks

		return (
		<Container>
			{/* 
			<h2>StacksNET&trade; <span role="img" aria-label="Stacks of Books">&#x1f4da;&#x1f4da;&#x1f4da;</span></h2>
			<h5 className="text-muted pl-4">Stacks721&trade; ERC721 Multi-Media Tokens</h5>
			*/}
			<div className="mt-4">
				<dl className="row">
					<dt className="col-sm-3 mb-2"> Selected Network </dt>
					<dd className="col-sm-9 small bg-light text-muted text-truncate">
						{/* 
						<h5>
							{ StacksLib.getNetworkName(this.context.Settings) }
						</h5>
						*/}
						<div>
							<Input name="selectNetwork" type="select" onChange={this.handleNetworkSelection} value={this.context.Settings.networkName}>
								<option value="">Select Network</option>
								{activeNetworks.map((selectNetwork, i) => {
									return <option key={i} value={selectNetwork}>{BlockTxns.purdyCamel(selectNetwork)}</option>
								})}
							</Input>
						</div>
					</dd>

					<dt className="col-sm-3 mb-2"> Active Account </dt>
					<dd className="col-sm-9 small bg-light text-muted text-truncate">
						{/* 
						<AccountData drizzle={drizzle} drizzleState={drizzleState} accountIndex={0} units="ether" precision={3} />
						*/}
						<div>
							<h5>
								<a href={this.context.Settings.BlockExplorerUrl+"/address/"+drizzleState.accounts[0]} target="_blank" rel="noopener noreferrer" ><span>&#8599;</span>{ drizzleState.accounts[0] }</a>
							</h5>
						</div>
						<div>
							{/* 
							<SwissArmy mode="getBalance" />
							*/}

							<StacksPrice price={ accountBalance } />

							{ " " + this.context.Settings.curr + " " }

							<StacksEstPrice price={ accountBalance } peg={ "0" } /> USD

						</div>
					</dd>


					<dt className="col-sm-3 mb-2"> Contract Name </dt>
					<dd className="col-sm-9 small bg-light">
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="Stacks721" method="name" />
					</dd>


					{/*
					<dt className="col-sm-3 mb-2"> Viewer Id </dt>
					<dd className="col-sm-9 small bg-light">
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="MediaLogicView" method="getViewerId" methodArgs={[drizzleState.accounts[0]]} />
					</dd>

					<dt className="col-sm-3 mb-2"> Stacks721 Address </dt>
					<dd className="col-sm-9 small bg-light">
						{ Stacks721.address }
					</dd>
					*/}

					<dt className="col-sm-3 mb-2"> Contract Symbol </dt>
					<dd className="col-sm-9 small bg-light">
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="Stacks721" method="symbol" />
					</dd>
					<dt className="col-sm-3 mb-2"> Total Tokens </dt>
					<dd className="col-sm-9 small bg-light">
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="Stacks721" method="totalSupply" />
					</dd>
					<dt className="col-sm-3 mb-2"> Your Tokens </dt>
					<dd className="col-sm-9 small bg-light">
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="Stacks721" method="balanceOf" methodArgs={[drizzleState.accounts[0]]} />
					</dd>

					<dt className="col-sm-3 mb-2"> Active Contracts</dt>
					<dd className="col-sm-9 small bg-light">
						{ this.context.drizzle.contracts ? Object.keys(this.context.drizzle.contracts).map( (c, i) => {
							const c_address = this.context.drizzle.contracts[c].address
							return <div key={i}>
								{c} {" "}
								<small>
									<a href={this.context.Settings.BlockExplorerUrl+"/address/"+c_address} target="_blank" rel="noopener noreferrer" ><span>&#8599;</span>{c_address}</a>
								</small>
							</div>
						} ) : ""
					} </dd>

					{/*
					<hr> </hr>
					<dt className="col-sm-3 mb-2"> Active Contracts</dt>
					<dd className="col-sm-9 small bg-light">
						{ this.context.drizzle.contracts ? Object.keys(this.context.drizzle.contracts).map( (c, i) => {
							return <div key={i}>
								{i} {" | "}
								{c}
							</div>
						} ) : ""
					} </dd>

					*/}


					{/*
					<dt className="col-sm-3 mb-2"> Account Roles </dt>
					<dd className="col-sm-9 small bg-light">
						{" Admin "}
						&bull;&nbsp;
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="AppLogic" method="hasRole" methodArgs={[1, drizzleState.accounts[0]]} />
						&nbsp;&nbsp;&ndash;&nbsp;&nbsp;
						{" Manager "}
						&bull;&nbsp;
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="AppLogic" method="hasRole" methodArgs={2, [drizzleState.accounts[0]]} />
						&nbsp;&nbsp;&ndash;&nbsp;&nbsp;
						{" Publisher "}
						&bull;&nbsp;
						<ContractData drizzle={drizzle} drizzleState={drizzleState} contract="AppLogic" method="hasRole" methodArgs={3, [drizzleState.accounts[0]]} />
					</dd>
					*/}
				</dl>
			</div>
		</Container>
		);
	}
}

export default HomePane;
