// StacksSeriesDetails - 20221109 j0zf at apogeeinvent.com
// StacksSeriesDetails.js 2022 0624 j0zf at apogeeinvent.com
// StacksSeries.js - 20220621 j0zf at apogeeinvent.com
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { ButtonGroup, Button, Container, Badge, Card, Row, Col, CardImg, CardHeader, CardText, CardFooter } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
import StrangeDice from "../utility/StrangeDice.js"
import StacksEstPrice from "../stacks/StacksEstPrice.js"

class StacksSeriesDetails extends React.Component {
	state = { seriesId: this.props.seriesId, contractId: this.props.contractId }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (!this.state.contractId || !this.state.seriesId) return;
		BlockTxns.contractCacheCall(this, "MarketLogic", "getContractListingId", { contractId: this.state.contractId, idType: "series", id: this.state.seriesId }, "getContractListingId_1")
	}
	render() {
		const listingId = Number(BlockTxns.getCacheValue(this, "MarketLogic", "getContractListingId", "getContractListingId_1", 0))
		return <StacksSeriesDetailsById2 listingId={listingId} />
	}
}

class StacksSeriesDetailsById2 extends React.Component {
	state = { listingId: this.props.listingId }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (!this.state.listingId) return;
		BlockTxns.contractCacheCall(this, "MarketLogic", "getListing", { listingId: this.state.listingId }, "getListing_1")
	}
	render() {
		const listing = BlockTxns.getCacheValue(this, "MarketLogic", "getListing", "getListing_1", {})
		return <StacksSeriesDetailsA listing={listing} />
	}
}

class StacksSeriesDetailsA extends React.Component {
	state = { listing: this.props.listing }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (!this.state.listing || !this.state.listing.seriesId) return;
		BlockTxns.contractCacheCall(this, "Stacks721", "getSeries", { seriesId: this.state.listing.seriesId }, "getSeries_1")
		BlockTxns.contractCacheCall(this, "AppLogic", "hasRole", { roleType: 2, account: this.context.account }, "hasManagerRole")
	}
	render() {
		const seriesData = BlockTxns.getCacheValue(this, "Stacks721", "getSeries", "getSeries_1", { seriesId: 0 })
		const hasManagerRole = BlockTxns.getCacheValue(this, "AppLogic", "hasRole", "hasManagerRole", false)
		return <StacksSeriesDetails2 listing={this.state.listing} seriesData={ seriesData } hasManagerRole={hasManagerRole} />
	}
}

class StacksSeriesDetails2 extends React.Component {
	state = { listing: this.props.listing, seriesData: this.props.seriesData, tokenCdnData: {}, isOpen: false, hasManagerRole: this.props.hasManagerRole }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		if (!this.state.seriesData || !this.state.listing || !this.state.listing.seriesId) return
		this.loadTokenJson()
	}

	loadTokenJson = () => {
		if (!this.state.seriesData || !this.state.seriesData.seriesURI) return
		try {
			const seriesURI = this.state.seriesData.seriesURI
			if (seriesURI !== "") {
				fetch(seriesURI)
				.then((resp) => resp.json())
				.then((respJson) => {
					this.setState({ tokenCdnData: respJson })
				}).catch(error => {
					throw(error)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	navToggle = () => {
		this.setIsOpen(!this.state.isOpen)
	}

	setIsOpen = (flg) => {
		this.setState({ isOpen: flg })
	}

	render() {
		const drizzleState = this.context.drizzleState
		const tokenOwner = this.state.listing.owner
		const seriesData = this.state.seriesData
		const isOwner = (drizzleState.accounts[0] === tokenOwner ? true : false)
		const seriesListing = this.state.listing
		seriesListing.price = ( seriesListing.price !== undefined ? seriesListing.price : 0 )
		seriesListing.peg = ( seriesListing.peg !== undefined ? seriesListing.peg : 0 )
		seriesListing.nativePrice = ( seriesListing.nativePrice !== undefined ? seriesListing.nativePrice : 0 )

		const tokenCdnData = this.state.tokenCdnData
		var issueCoverUrl = "";
		var softcopyLink = "";
		var issueTitle = "";
		var creatorProfileLink = "";
		var creatorUsername = "";
		var seriesTitle = "";
		if (tokenCdnData.id+"" === "0") {
			issueCoverUrl = tokenCdnData.image;
			softcopyLink = tokenCdnData.external_link;
			issueTitle = tokenCdnData.issue_title;
			creatorProfileLink = tokenCdnData.creator_link;
			creatorUsername = tokenCdnData.creator_name;
			seriesTitle = tokenCdnData.series_title;
		}

		const buyNewLink = "./buynew?listingId=" + this.state.listing.listingId + "&_payableAmount_=" + seriesListing.nativePrice + "&to=" + drizzleState.accounts[0]
		const c_Stacks721_address = this.context.drizzle.contracts['Stacks721'].address
		const c_StacksStore_address = this.context.drizzle.contracts['StacksStore'].address
		const setMintableSaleLink =  "./forms?contract=SalesLogic&method=setMintable&listingId="+ this.state.listing.listingId +"&contractId="+ seriesListing.contractId +"&owner="+ seriesListing.owner +"&seriesId=" + Number(this.state.listing.seriesId) + "&price=" + seriesListing.price + "&peg=" + seriesListing.peg 
		const blockExplorerSeriesOwnerLink = this.context.Settings.BlockExplorerUrl+"address/"+seriesListing.owner
		const openSeaLink = "https://opensea.io/" + seriesListing.owner
		const forSaleFlag = ""+seriesListing.nativePrice !== "0" && parseInt(seriesData.issued) < parseInt(seriesData.limit)
		const holdingsLink = "./holdings?account=" + seriesListing.owner

		return (
			<Container>
			<Row>
			<Col>
				<Row>
					<h2 className="ml-2 mt-2">{issueTitle}</h2>
				</Row>
				<Row>
					<h5 className="text-muted"><span className="text-muted">{seriesTitle}</span> <a href={creatorProfileLink} target="" rel="noopener noreferrer">{creatorUsername}</a></h5>
				</Row>
				<Row>
					<ButtonGroup size="lg" style={{ width: '100%'}} className="mt-4 mb-1">
						<Button outline href={softcopyLink}>View Content</Button>

						{ isOwner ? 
							<Button outline href={setMintableSaleLink}>Set Mint<span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span></Button>
						: "" }
						
						{ forSaleFlag ?
							<Button outline href={buyNewLink} color="primary">Buy New</Button>
						: "" }

					</ButtonGroup>
				</Row>
				<Row>
					<h6 className="text-muted ml-auto mr-auto mb-4 mt-1">
						{ forSaleFlag ? 
								<strong>
									{ BlockTxns.currDraw(this, seriesListing.nativePrice) }
									{' ' + this.context.Settings.curr}
									{' | Est. Price '}
									<StacksEstPrice price={ seriesListing.price } peg={ seriesListing.peg } />
									{' USD '}
								</strong>
							:
								"No New Mints For Sale" 
						}
					</h6>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1">
						Mintable Token Series
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1">
						Issued {seriesData.issued} 
						{'  |  '}
						Limited {seriesData.limit}
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1 text-truncate">
						{ this.context.account === seriesListing.owner ?
							<span>Your Series <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span> </span>
						:
							"Series Owner "
						}
						<a href={"./listings?owner="+seriesListing.owner}>{seriesListing.owner}</a>
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1 text-truncate">
						{'Token Contract '}
						<a href={this.context.Settings.BlockExplorerUrl+"address/"+c_Stacks721_address} target="_blank" rel="noopener noreferrer" ><span>&#8599;</span>{c_Stacks721_address}</a>
					</h7>
				</Row>
				<Row>
					<h7 className="text-muted ml-auto mr-auto mb-1 mt-1 text-truncate">
						{'Store Contract '}
						<a href={this.context.Settings.BlockExplorerUrl+"address/"+c_StacksStore_address} target="_blank" rel="noopener noreferrer" ><span>&#8599;</span>{c_StacksStore_address}</a>
					</h7>
				</Row>
				<Row>
					<hr className="text-muted ml-auto mr-auto mb-4 mt-1" />
				</Row>
				<Row className="overflow-auto text-center">
					<div className="text-center">
						{ tokenCdnData.attributes ? tokenCdnData.attributes.map( (obj, i) => {
							return ( (obj.value !== undefined && obj.value !== "") ? 
									<Badge key={i} className="" style={{ padding: '0.6rem', margin: '0.25rem', fontSize: '1.2rem', backgroundColor: '#3DB1AD', opacity: '0.8' }}> <strong>{obj.trait_type}</strong> <br /> <em>{obj.value}</em> </Badge> 
								: "" )
						}) : "" }
					</div>
				</Row>
				<Row>
					<hr className="text-muted ml-auto mr-auto mb-4 mt-1" />
				</Row>

				{ this.state.hasManagerRole ? 
				<Row>
					<div className="text-muted text-small ml-auto mr-auto mb-1 mt-1 text-truncate">
						<a href={setMintableSaleLink}>Set Mintable</a>
						{' | '}
						<a href={holdingsLink} rel="noopener noreferrer" target="">Hodl</a>
						{' | '}
						<a href={blockExplorerSeriesOwnerLink} rel="noopener noreferrer" target="_blank"><span>&#8599;</span>Publisher</a>
						{' | '}
						<a href={openSeaLink} rel="noopener noreferrer" target="_blank"><span>&#8599;</span>Sea</a>
					</div>
				</Row>
				: "" }

			</Col>
			<Col xs s md lg xl="5">
				<Card style={{ width: '100%', minWidth: '16rem', maxWidth: '40rem' }} className="mb-4">
					<CardHeader className="m-1 p-1">
						<Row className="flex-nowrap">
							<Col className="text-center">
								<div className="ml-2 overflow-hidden pl-auto pr-auto text-center">
									{seriesData.symbol} 
									&nbsp;|&nbsp;
									{seriesData.symbol !== "" ? 
										<span>
											{" "}
											{ BlockTxns.currSymbol(this.context.Settings.curr, {}) }
										</span>
									:
										<span>
											<StrangeDice diceDo={{}} />
										</span>
									}
									{ isOwner ? <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span> : '' }
								</div>
							</Col>
						</Row>
					</CardHeader>

					<a rel="noopener noreferrer" href={softcopyLink}>
						<CardImg top width="100%" src={issueCoverUrl} alt={issueTitle} />
					</a>

					<CardFooter>
						<CardText tag="small" className="text-muted">
							<Row>
								<Col className="text-center">
									<div className="ml-auto mr-auto text-center"> Mintable Token Series </div>
								</Col>
							</Row>
						</CardText>
					</CardFooter>
				</Card>
			</Col>
			</Row>
			</Container>
		)
	}
}

export default StacksSeriesDetails;
