// SeriesTokenPane.js 2022 0713 j0zf at apogeeinvent.com
// TokenPane.js 20210415 j0zf at ApogeeINVENT

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin';
import { Container, Input } from "reactstrap";
import StacksToken from "../stacks/StacksToken"
import StacksTokenDetails from "../stacks/StacksTokenDetails"
import BlockTxns from "../utility/BlockTxns.js"
import equal from 'fast-deep-equal'

class SeriesTokenPane extends React.Component {
	state = { fields: { seriesId: (this.props.queryObject.seriesId ? this.props.queryObject.seriesId : 0), number: (this.props.queryObject.number ? this.props.queryObject.number : 0) } }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "Stacks721", "getSeriesToken", { 'seriesId': this.state.fields.seriesId, 'number': this.state.fields.number }, "getSeriesToken_result")
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	getFieldState = (fname) => {
		return (this.state.fields[fname] === undefined ? "" : this.state.fields[fname])
	}

	handleFieldChange = (e) => {
		var fields = this.state.fields
		fields[e.target.name] = e.target.value
		this.setState({ fields }, this.queryContracts)
	}

	render() {
		const tokenId = BlockTxns.getCacheValue(this, "Stacks721", "getSeriesToken", "getSeriesToken_result", 0)
		return (
		<Container>
			<div className="clearfix">
				<div>
					<h2>Stacks721 Media Token</h2>
				</div>
				<div style={{'width': '60%', 'minWidth': '30rem', 'maxWidth': '40rem', 'float': 'left' }}>
					<dl className="row">
						<dt className="col-sm-4 mb-2 pt-2"> Series Id </dt>
						<dd className="col-sm-8 text-truncate small">
							<Input className="m-1" type="number" name="seriesId" placeholder="Series Id" value={this.getFieldState('seriesId')} onChange={this.handleFieldChange} min="0" step="1" />
						</dd>
					</dl>
					<dl className="row">
						<dt className="col-sm-4 mb-2 pt-2"> Series Number </dt>
						<dd className="col-sm-8 text-truncate small">
							<Input className="m-1" type="number" name="number" placeholder="Number" value={this.getFieldState('number')} onChange={this.handleFieldChange} min="0" step="1" />
						</dd>
					</dl>
					<StacksTokenDetails tokenId={ tokenId } />
				</div>
				<div style={{'width': '20rem', 'float': 'left'}} className="ml-4 mt-4">
					<StacksToken tokenId={ tokenId } />
				</div>
			</div>
			{/* 
			<div> { JSON.stringify(this.state) } </div>
			*/}
		</Container>
		)
	}
}

export default SeriesTokenPane
