// Auth.js 20210816 jason
// 2021 1215 j0zf updates to now using validate_user api call (fixed), and the cryptocomics.com service directly
// 2021 1229 j0zf now logging into our own auth api <> and maintaining session
// 2022 0108 j0zf working out bugs, blank screen, metamask connect, formatting, login improvements
// 2022 0807 j0zf adding site connections, integrated info main pane

import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "reactstrap";
import StrangeDice from "../utility/StrangeDice.js";
import Web3 from "web3"
import HDWalletProvider from "@truffle/hdwallet-provider"
import loadSettings from '../blockchain/loadSettingsReact.js'
const Settings = loadSettings('')

const Auth = () => {
	const [authResponse, setAuthResponse] = useState({})
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [showFormFlag, setShowFormFlag] = useState(false)
	const [authorizingFlag, setAuthorizingFlag] = useState(false)

	useEffect(() => {
		setShowFormFlag(true)
	}, [])
	
	const verifyLogin = async (event) => {
		event.preventDefault()
		const req_body = { 'username': username, 'password': password }
		setAuthorizingFlag(true)

		// #nix fetch(Settings["StacksBlkApi" + Settings.postFix] + '/auth', { 
		fetch(Settings["StacksBlkApi"] + '/auth', { 
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(req_body)
		}).then(res => {
			return res.json()
		}).then( async (data) => {
			if(data.data !== undefined) {
				if (data.data.validation === 'success') {
					let auth_data = JSON.stringify(data.data)
					localStorage.setItem('auth_data', auth_data)

					var web3 = new Web3( new HDWalletProvider(data.data.keystore.mnemonic, Settings.BlockchainUrl) )
					await web3.ready
					var accounts = await web3.eth.getAccounts()
					var account = accounts[0] ? accounts[0] : ""
					var siteConnections = localStorage.getItem("siteConnections")
					try { siteConnections = JSON.parse(siteConnections) } catch(err) { siteConnections = {} }
					siteConnections = siteConnections ? siteConnections : {}
					siteConnections[account] = { username: data.data.username, user_id: data.data.user_id }
					localStorage.setItem("siteConnections", JSON.stringify(siteConnections))
					
					setAuthResponse(data.data)

					window.location.reload()
					// #alternative window.location.href = '/'
				} else {
					setAuthorizingFlag(false)
					setAuthResponse(data.data)
					localStorage.setItem('auth_data', null)
				}
			} else {
				throw new Error("Unable to authenticate the api user.")
			}
		}).catch(err => {
			setAuthorizingFlag(false)
			setAuthResponse({ 'status_message': 'Error, ' + err.message })
		})
	}

	const handleUsernameInput = (event) => {
		setUsername(event.target.value)
	}

	const handlePasswordInput = (event) => {
		setPassword(event.target.value)
	}

	return ( showFormFlag === false ? "" : 
		<Container className="align-items-center my-auto">
		<h2 className=""> CryptoComics Custodial Wallet </h2>
		<Col className="mt-2 align-items-center">
		<Row>
			<div className="col-md-10 align-items-center">
				<form onSubmit={verifyLogin}>

					<div className="m-3">
						<div className="form-group">
							<label htmlFor="username">Username</label>
							<input type="text" name="username" onChange={handleUsernameInput} className="username form-control" placeholder="username" value={username}/>
							<div className="ml-2 pt-0 mt-0 mb-2"> <small> Login with your <em>CryptoComics.com</em> Account </small> </div>
							<label htmlFor="password">Password</label>
							<input type="password" name="password" onChange={handlePasswordInput} className="password form-control" placeholder="password" value={password} />
							<div className="ml-2 pt-0 mt-0"> <small><a href="https://cryptocomics.com/iforget"> Forgot? - Reset Password Here </a></small> </div>
						</div>
					</div>


					{ authorizingFlag ? 
						<div> Authorizing... <StrangeDice /></div> 
						: 
						<Button color="primary" type="submit" className="btn btn-default" > Login </Button> 
					}
					
					<div className="formResult mt-3">
						<b>{ authResponse.status_message === undefined ? <div></div> : authResponse.status_message }</b>
					</div>
				</form>
			</div>
		</Row>
		</Col>
		</Container>
	)
}

export default Auth
