// Main.js - CryptoSTACKS 2021-04-10 j0zf at ApogeeINVENT

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin';
import { Row, Col, Nav, NavItem, NavLink, Container, Navbar, NavbarBrand, NavbarToggler, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from "reactstrap";
import DrizNetworkName from '../utility/DrizNetworkName'
import Auth from "../layout/Auth"
import StrangeDice from "../utility/StrangeDice"
import HomePane from './HomePane.js'
import InfoPane from './InfoPane.js'
import HoldingsPane from './HoldingsPane.js'
// import DepositPane from './DepositPane.js'
import CollectionPane from './CollectionPane.js'
import ListingsPane from './ListingsPane.js'
import SeriesTokenPane from './SeriesTokenPane.js'
import TokenPane from './TokenPane.js'
import TokensPane from './TokensPane.js'
import SeriesPane from './SeriesPane.js'
import DynamicPane from './DynamicPane.js'
import EthAuthPane from './EthAuthPane.js'
import StacksWallet from "../stacks/StacksWallet.js"
import equal from "fast-deep-equal"
import StacksLib from "../stacks/StacksLib.js"
import BlockTxns from '../utility/BlockTxns.js'
const queryString = require("query-string")

class Main extends React.Component {
	state = { activePane: "", isOpen: false, auth_data: this.props.auth_data, account: this.props.account,  connectionSuccess: this.props.connectionSuccess, connectionMode: this.props.connectionMode, siteConnections: this.props.siteConnections }
	static contextType = DrizzleContext.Context
	queryObject = {}
	refreshTimeout = null

	componentDidMount() {
		this.mainUpdate()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.mainUpdate)
		}
	}

	mainUpdate = () => {
		this.queryObject = queryString.parse(document.location.search)
		// const connectionMode = localStorage.getItem('connectionMode')
		const auth_data = this.state.auth_data

		//if (this.props.initialized && this.props.connectionSuccess && this.state.account !== "") {
		if (this.props.initialized && this.props.connectionSuccess && this.state.account !== "" && this.context.Settings.BlockchainNetworkId === this.context.drizzleState.web3.networkId) {
			clearTimeout(this.refreshTimeout)
			switch (document.location.pathname) {
				// case '/auctions': this.setPane(document.location.pathname); break;
				// case '/trades': this.setPane(document.location.pathname); break;
				// case '/bids': this.setPane(document.location.pathname); break;
				// case '/viewer': this.setPane(document.location.pathname); break;
				// case '/publish': this.setPane(document.location.pathname); break;
				// case '/mint': this.setPane(document.location.pathname); break;
				// case '/ask': this.setPane(document.location.pathname); break;
				case '/buynew': this.setPane(document.location.pathname); break;
				case '/buy': this.setPane(document.location.pathname); break;
				// case '/test': this.setPane(document.location.pathname);break;
				case '/dynamic': this.setPane(document.location.pathname); break;
				case '/forms': this.setPane(document.location.pathname); break;
				// case '/deposit': this.setPane(document.location.pathname); break;
				case '/withdrawal': this.setPane(document.location.pathname); break;
				case '/series': this.setPane(document.location.pathname); break;
				case '/seriestoken': this.setPane(document.location.pathname); break;
				case '/token': this.setPane(document.location.pathname); break;
				case '/tokens': this.setPane(document.location.pathname); break;
				case '/collection': this.setPane(document.location.pathname); break;
				case '/listings': this.setPane(document.location.pathname); break;
				case '/holdings': this.setPane(document.location.pathname); break;
				case '/info': this.setPane('/info'); break;
				case '/details': this.setPane('/details'); break;
				case '/ethauth': this.setPane('/ethauth'); break;
				case '/home':
				case '/': this.setPane('/home'); break;
				default: this.setPane(''); break;
			}
		} else if (this.state.connectionMode === "CryptoComics" && auth_data && auth_data.username) {
			this.refreshTimeout = setTimeout(() => { document.location.reload() }, this.context.Settings.GiveUpConnectionRefreshTimeout) // 4000 is 4 seconds
			this.setPane('/loading')
		} else if (this.state.connectionMode === "CryptoComics") {
			this.setPane('/auth')
		} else {
			this.setPane('/ethauth')
		}
	}

	setPane = (pane) => {
		if (this.state.activePane !== pane) this.setState({activePane:pane})
	}

	navToggle = () => {
		this.setIsOpen(!this.state.isOpen)
	}

	setIsOpen = (flg) => {
		this.setState({isOpen:flg})
	}

	logoffClick = (event) => {
		event.preventDefault()
		localStorage.clear()
		window.location.reload()
	}

	render() {
		// Ref: https://reactstrap.github.io/components/navbar/
		// const drizzleState = this.context.drizzleState
		// const connectionMode = localStorage.getItem('connectionMode')
		// console.log("Main.js :: this.context", this.context)

		const oMenu = (
			<Navbar color="light" light expand="md" fixed="top">
			<NavbarBrand href="https://cryptocomics.com/dashboard">
				{/* 
				<img height="36" src="./StacksNETioMainLogo.png" alt="StacksNET.io Stacks and Stacks and Stacks of Media" />
				*/}
				{ StacksLib.drawMainLogo(this.context.Settings) }
			</NavbarBrand>
			<NavbarToggler onClick={this.navToggle} />
			<Collapse isOpen={this.state.isOpen} navbar>
			<Nav className="mr-auto" navbar>
				<NavItem> <NavLink href={"https://cryptocomics.com/dashboard"}> Dashboard </NavLink> </NavItem>
				<NavItem> <NavLink href={"/collection?owner="+this.state.account}> Collection </NavLink> </NavItem>
				<NavItem> <NavLink href={"/listings?owner="+this.state.account}> Listings </NavLink> </NavItem>
				<NavItem> <NavLink href="/holdings"> Holdings </NavLink> </NavItem>
				<NavItem> <NavLink href="/details"> Network </NavLink> </NavItem>
				<NavItem> <NavLink href="/details"> { BlockTxns.currSymbol(this.context.Settings.curr, {}) } </NavLink> </NavItem>
				{/* 

										{ BlockTxns.currSymbol(this.context.Settings.curr, {}) }
				<NavItem> <NavLink href="/details"> <span>&middot;</span> </NavLink> </NavItem>
				*/}
				<NavItem> <NavLink href="https://cryptocomics.com/marketplace"> Marketplace </NavLink> </NavItem>
			</Nav>
			<NavbarText>
				<UncontrolledDropdown className="p-0 m-0">
					<DropdownToggle nav className="p-0 m-0">
						{!!this.state.auth_data.username ? this.state.auth_data.username : 'Anonymous'}{ this.context.initialized ? " On " : "" }<DrizNetworkName />
					</DropdownToggle>
					<DropdownMenu className="overflow-hidden">
						
						<StacksWallet account={this.state.account} connectionSuccess={this.state.connectionSuccess} connectionMode={this.state.connectionMode} auth_data={this.state.auth_data} siteConnections={this.state.siteConnections} />
						
						<DropdownItem divider />
						<Row>
							<Col>
								<NavLink href="/details">Switch&nbsp;Wallet</NavLink>
							</Col>
							<Col className="text-right">
								<NavLink onClick={this.logoffClick}>Logoff</NavLink>
							</Col>
						</Row>
					</DropdownMenu>
				</UncontrolledDropdown>
			</NavbarText>
			</Collapse>
			</Navbar>
		)

		var oBody = (<div></div>);
		switch (this.state.activePane) {
			// case '/auctions': oBody = (
			// 	<StacksPagedDataLists headline="Auctions" cardHead="Auction" cardFoot=""  contract="MediaLogicExt" method="getAuctionData" countMethod="getAuctionCount"  tokenPane={true} hideControls={this.queryObject.hideControls === 'true' ? true : false} queryObject={this.queryObject} />
			// ); break;
			// case '/trades': oBody = (
			// 	<StacksPagedDataLists headline="Trade Offers" cardHead="Trade Offer" cardFoot=""  contract="TradeLogic" method="getTradeData" countMethod="getTradeCount"  tokenPane={true} hideControls={this.queryObject.hideControls === 'true' ? true : false} queryObject={this.queryObject} />
			// ); break;
			// case '/bids': oBody = (
			// 	<StacksPagedDataLists headline="Bids" cardHead="Bid" cardFoot=""  contract="MediaLogicExt" method="getBidData" countMethod="getBidCount"  tokenPane={true} hideControls={this.queryObject.hideControls === 'true' ? true : false} queryObject={this.queryObject} />
			// ); break;

			case '/forms': oBody = (
				<DynamicPane contract={this.queryObject['contract']} method={this.queryObject['method']} tokenPane={this.queryObject.tokenPane === 'true' ? true : false} hideControls={this.queryObject.hideControls === 'true' ? true : false} queryObject={this.queryObject} />
			); break;

			case '/dynamic': oBody = (
				<DynamicPane contract={this.queryObject['contract']} method={this.queryObject['method']} tokenPane={this.queryObject.tokenPane === 'true' ? true : false} hideControls={this.queryObject.hideControls === 'true' ? true : false} queryObject={this.queryObject} />
				//<DynamicPane contract={this.queryObject['contract']} method={this.queryObject['method']} tokenPane={this.queryObject.tokenPane === 'true' ? true : false} hideControls={this.queryObject.hideControls === 'true' ? true : false} queryObject={this.queryObject} menu={[{label: "", link: "/dynamic?method="}]} />
			); break;

			// case '/viewer': 
			// 	// var auth_data_json = localStorage.getItem('auth_data')
			// 	// var viewerId = 0
			// 	// if (auth_data_json) { 
			// 	// 	var auth_data = JSON.parse(auth_data_json)
			// 	// 	if (auth_data.keystore.viewer_id) {
			// 	// 		viewerId = auth_data.keystore.viewer_id
			// 	// 	}
			// 	// }
			// 	var viewerId = (this.state.auth_data.keystore && this.state.auth_data.keystore.viewer_id ? this.state.auth_data.keystore.viewer_id : 0)
			// 	if (this.queryObject.viewerId === undefined) {
			// 		this.queryObject.viewerId = viewerId
			// 	}
			// 	oBody = (
			// 	/* 
			// 	<ViewerPane queryObject={this.queryObject} />
			// 	<DynamicPane headline="Viewer App Identifier" contract="MediaLogic" method="setAccountViewerId" hideControls={true} queryObject={this.queryObject} />
			// 	*/
			// 	<div>
			// 		<div>ViewerId: {viewerId} </div>
			// 		<DynamicPane headline="Viewer App Identifier" contract="MediaLogic" method="setViewer" hideControls={true} queryObject={this.queryObject} />
			// 	</div>
			// ); break

			// case '/deposit': oBody = ( <DepositPane queryObject={this.queryObject} /> ); break;
			case '/withdrawal': oBody = ( <DynamicPane headline="Withdraw Holdings" contract="AccountingLogic" method="withdrawHoldings" hideControls={true} queryObject={this.queryObject} />); break;

			// #nix? case '/publish': oBody = ( <DynamicPane headline="Publish a Stacks721&trade; Token" contract="Stacks721" method="setSeries" tokenPane={false} hideControls={true} queryObject={this.queryObject} />); break;
			// case '/mint': oBody = ( <DynamicPane headline="Mint Stacks721&trade; Tokens" contract="Stacks721" method="mint" tokenPane={false} hideControls={true} queryObject={this.queryObject} />); break;
			case '/buynew': oBody = ( <DynamicPane headline="Buy New Stacks721&trade; Token" contract="SalesLogic" method="buyNew" tokenPane={true} hideControls={true} queryObject={this.queryObject} />); break;
			case '/buy': oBody = ( <DynamicPane headline="Buy Stacks721&trade; Token" contract="SalesLogic" method="buy" tokenPane={false} hideControls={true} queryObject={this.queryObject} />); break;

			// case '/publish': oBody = ( <PublishPane headline="Publish a Stacks721&trade; Token" contract="MediaLogic" method="publish" queryObject={this.queryObject} /> ); break
			// case '/ask': oBody = ( <DynamicPane headline="Set Asking Price" contract="MediaLogic" method="setAskingPrice" tokenPane={true} hideControls={true} queryObject={this.queryObject} />); break;
			// case '/buy': oBody = ( <DynamicPane headline="Buy a Stacks721&trade; Token" contract="MediaLogic" method="buy" tokenPane={true} hideControls={true} queryObject={this.queryObject} />); break;
			// case '/test': oBody = (<TestPane queryObject={this.queryObject} />); break;

			case '/token': oBody = (<TokenPane queryObject={this.queryObject} />); break
			case '/seriestoken': oBody = (<SeriesTokenPane queryObject={this.queryObject} />); break
			case '/series': oBody = (<SeriesPane queryObject={this.queryObject} />); break
			case '/tokens': oBody = (<TokensPane queryObject={this.queryObject} />); break
			case '/collection': oBody = (<CollectionPane queryObject={this.queryObject} />); break
			case '/listings': oBody = (<ListingsPane queryObject={this.queryObject} />); break
			case '/holdings': oBody = (<HoldingsPane queryObject={this.queryObject} />); break
			case '/info': oBody = (<InfoPane queryObject={this.queryObject} />); break
			case '/details': oBody = (
				<div>
					<EthAuthPane queryObject={this.queryObject} connectionMode={this.state.connectionMode} auth_data={this.state.auth_data} account={this.state.account} />
					<HomePane queryObject={this.queryObject} />
				</div>
			); break
			case '/ethauth': oBody = (<EthAuthPane queryObject={this.queryObject} connectionMode={this.state.connectionMode} auth_data={this.state.auth_data} account={this.state.account} />); break
			//case '/home': oBody = (<HomePane queryObject={this.queryObject} />); break
			case '/home': oBody = (<ListingsPane queryObject={this.queryObject} />); break
			case '/auth': oBody = ( 
				<div>
					<EthAuthPane queryObject={this.queryObject} connectionMode={this.state.connectionMode} auth_data={this.state.auth_data} account={this.state.account} />
					<Auth queryObject={this.queryObject} />
				</div>
			); break

			case '/loading':
			default:
			oBody = (<StrangeDice />)
		}

		const oFooter = (<div className="clearfix">
			<Nav style={{float: 'left'}}>
				<NavbarBrand href="/details">
					<img height="36" src="./StacksNETioSquareLogo.png" alt="StacksNET.io Stacks and Stacks and Stacks of Media" />
				</NavbarBrand>
				<NavItem> <NavLink href="/forms"> Blockchain Contracts </NavLink> </NavItem>
				{/* 
				<NavItem> <NavLink href="https://expedition.dev/?network=StacksNET&rpcUrl=https://stacks1.stacksnet.io" target="_blank"> Explorer </NavLink> </NavItem>
				*/}
				<NavItem> <NavLink href={this.context.Settings.BlockExplorerUrl} target="_blank"> <span>&#8599;</span>Explorer </NavLink> </NavItem>
				<NavItem> <NavLink href="https://cryptocomics.com/faq" target="">FAQ</NavLink> </NavItem>
				{/* 
				<NavItem> <NavLink href={this.context.Settings.publicProjectLink} target="_blank"> <span>&#8599;</span><span role="img" aria-label="Early Beta Access">&beta;</span> </NavLink> </NavItem>
				*/}
				<NavItem> <NavLink href="https://cryptocomics.com/contact" target="">Contact Us</NavLink> </NavItem>
			</Nav>
			<Nav style={{float: 'right'}}>
				<NavItem className="mr-0 pr-0"> <NavLink className="mr-0 pr-1 text-muted" href="https://cryptocomics.com/" target=""> For CryptoComics.com </NavLink> </NavItem>
				<NavItem className="ml-0 pl-0"> <NavLink className="ml-0 pl-1 text-muted" href="https://apogeeinvent.com/" target="_blank"> <span>&#8599;</span>by ApogeeINVENT </NavLink> </NavItem>
			</Nav>
		</div>)

		return (
		<Container>
			<div className="">{oMenu}</div>
			<hr className="mt-5 mb-2 pt-2" />
			<div className="mt-5 pt-0">{oBody}</div>
			{/* 
			<hr />
				{ this.context.initialized ?
					<div>
						{ this.context.Settings.BlockchainNetworkId } &lt;= this.context.Settings.BlockchainNetworkId !== this.context.drizzleState.web3.networkId =&gt; { this.context.drizzleState.web3.networkId }
					</div>
				: "" }
			*/}
			<hr />
			<div>{oFooter}</div>
		</Container>
		)
	}
}

export default Main
