// StacksTokens.js - 20210502 j0zf at ApogeeINVENT
// StacksOwnerTokens.js - 20210424 j0zf at ApogeeINVENT
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import StacksToken from "../stacks/StacksToken"
import equal from 'fast-deep-equal'
import { CardDeck, Container, PaginationLink, PaginationItem, Pagination } from "reactstrap"
import BlockTxns from "../utility/BlockTxns.js"

class StacksTokens extends React.Component {
	state = { page: this.props.page, pageSize: 12 }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "Stacks721", "totalSupply", {}, "totalSupply_results")
	}

	render() {
		const totalSupply = Number(BlockTxns.getCacheValue(this, "Stacks721", "totalSupply", "totalSupply_results", 0))
		const pageSize = Number(this.state.pageSize)
		const pageCount = Math.ceil( totalSupply / pageSize )
		const currNumber = this.state.page && this.state.page > 1 ? Number(this.state.page) : 1
		const prevNumber = currNumber > 1 ? currNumber - 1 : 1
		const nextNumber = currNumber < pageCount ? currNumber + 1 : currNumber
		var pageNumbers = []

		for (var xx=1; xx<=pageCount; xx++) {
			if (xx === 1 || xx === pageCount || xx === currNumber || xx === prevNumber || xx === nextNumber
				|| xx === Math.floor(currNumber/2) || xx === currNumber + Math.floor((pageCount-currNumber)/2)
				|| xx === currNumber - Math.floor(currNumber/4) || xx === currNumber + Math.floor((pageCount-currNumber)/4)
			) {
				pageNumbers.push(xx)
			}
		}

        var indexes = []
		const tokStart = totalSupply - ((currNumber - 1) * pageSize)
		const tokEnd = (tokStart - pageSize + 1 < 1 ? 1 : tokStart - pageSize + 1)
		for (var i=tokStart; i >= tokEnd; i--) {
			indexes.push(i);
		}

		return ( <Container>
			<div className="clearfix">
				<h4 style={{'float': 'left'}} className="text-muted">
					{totalSupply} Media Tokens
					&infin;
					{ tokStart < 1 ? 
						" O.o "
						: 
						<span> {tokStart} to {tokEnd} </span>
					}
				</h4>
				<Pagination style={{'float': 'right'}}>
					<PaginationItem  disabled={currNumber <= 1}>
						<PaginationLink previous href={"?page="+prevNumber} />
					</PaginationItem>
					<PaginationItem disabled={currNumber >= pageCount}>
						<PaginationLink next href={"?page="+nextNumber} />
					</PaginationItem>
				</Pagination>
			</div>
			<CardDeck>
				{indexes.map((index_, i) => {
					return <StacksToken key={i} tokenId={index_} />
				})}
			</CardDeck> 
			<div className="mt-3">
			<Pagination>
				<PaginationItem  disabled={currNumber <= 1}>
					<PaginationLink previous href={"?page="+prevNumber} />
				</PaginationItem>
				{pageNumbers.map(( pg, i) => {
					return (
						<PaginationItem key={i} disabled={pg === currNumber}>
							<PaginationLink key={i} href={"?page=" + pg}> {pg} </PaginationLink>
						</PaginationItem>
					)
				})}
				<PaginationItem disabled={currNumber >= pageCount}>
					<PaginationLink next href={"?page="+nextNumber} />
				</PaginationItem>
			</Pagination>
			</div>
		</Container> )
	}

}

export default StacksTokens
