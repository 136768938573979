// StacksWallet.js 2022 0724 j0zf at apogeeinvent.com
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
// import { DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown, Card, Row, Col, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, CardFooter } from "reactstrap"
import { Card, CardHeader, Input, Button } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
import StacksEstPrice from "../stacks/StacksEstPrice.js"
import StacksPrice from "../stacks/StacksPrice.js"
import { SiweMessage } from 'siwe';
import { ethers } from 'ethers'
// #nix const Settings = require('../blockchain/settings.json')
// #nix const loadSettings = require('../blockchain/loadSettings.js')
// #nix import loadSettings from '../blockchain/loadSettingsReact.js'
// #nix const Settings = loadSettings('')

class StacksWallet extends React.Component {
	state = { contractId: 1, account: this.props.account, connectionSuccess: this.props.connectionSuccess, connectionMode: this.props.connectionMode, auth_data: this.props.auth_data, siteConnections: this.props.siteConnections, accountAddressSelected: false, siteConnectionMessage: "" }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (this.context.initialized) {
			if (this.state.account !== this.context.drizzleState.accounts[0]) {
				this.setState({ account: this.context.drizzleState.accounts[0] }, this.queryContracts)
			}
			BlockTxns.cacheAccountBalance(this, this.state.account)
		}
	}

	handleAccountAddressFocus = (e) => {
		e.preventDefault()
		e.target.select()
		document.execCommand('copy')
		e.target.focus()
		this.setState({ accountAddressSelected: true })
	}

	handleSiteConnect = async (e) => {
		e.preventDefault()

		try {
			const web3 = this.context.drizzle.web3
			var account = this.state.account
			var nonce = ""

			const message = new SiweMessage({
				domain: document.location.host,
				address: account,
				chainId: await web3.eth.getChainId(),
				uri: document.location.origin,
				version: '1',
				statement: 'Connect as CryptoComics.com User: ' + this.state.auth_data.username,
				user_id: this.state.auth_data.user_id,
				nonce,
			})

			const preparedMessage = message.prepareMessage()
			const provider = new ethers.providers.Web3Provider(web3.currentProvider)
			const signature = await provider.getSigner().signMessage(preparedMessage);
			console.log("message => ", message)
			console.log("preparedMessage => ", preparedMessage)
			console.log("signature => ", signature)

			// #nix fetch(Settings["StacksBlkApi" + Settings.postFix] + '/ethauth', { 
			fetch(this.context.Settings["StacksBlkApi"] + '/ethauth', { 
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({ message, signature })
			}).then(res => {
				return res.json()
			}).then(data => {

				if (data.status && data.status === "success") {
					console.log("SUCCESS data => ", data)
					const siteConnections = this.state.siteConnections
					siteConnections[this.state.account] = { username: this.state.auth_data.username, user_id: this.state.auth_data.user_id }
					localStorage.setItem("siteConnections", JSON.stringify(siteConnections))
					this.setState({ siteConnectionMessage: "Successfully Connected" })
				} else {
					console.log("FAIL data => ", data)
					this.setState({ siteConnectionMessage: "Not authorized, login first" })
				}
			}).catch(err => {
				this.setState({ siteConnectionMessage: "Error Connecting" })
				console.log("catch error => ", err)
				console.log("handleSiteConnect() Caught Fetch Error", err)
			})
		} catch(err) {
			this.setState({ siteConnectionMessage: "Failed to Connect" })
			console.log("handleSiteConnect() Caught Error", err)
		}
	}

	handlePageRefresh = () => {
		window.location.reload()
	}

	render() {
		const accountBalance = BlockTxns.getCacheAccountBalance(this, this.state.account)
		const connectionMode = localStorage.getItem('connectionMode')
		return <Card className="m-1" style={{width: '16rem', minWidth: '16rem', maxWidth: '16rem'}}>
			<CardHeader className="m-1 p-1 text-center font-weight-bold" onClick={this.navToggle}>
				{connectionMode} Wallet
			</CardHeader>

			{ this.state.connectionSuccess && this.state.account && this.state.account !== "" ?
				<div>
				<div className="p-1 m-1 text-center small">
					<Input value={this.state.account} readOnly onFocus={this.handleAccountAddressFocus} />
					<div className="text-small">{ this.state.accountAddressSelected ? "copied to clipboard" : "" }</div>
					
				</div>
				<div className="text-center">
					{ this.state.siteConnections && this.state.siteConnections[this.state.account] ? 
						<Button size="sm" color="light" style={{ width: '94%' }} onClick={this.handleSiteConnect} > Connected to CryptoComics </Button>
					:
						<Button outline color="primary" size="sm" style={{ width: '94%' }} onClick={this.handleSiteConnect} > Connect to CryptoComics </Button>
					}
					<div className="text-small"> { this.state.siteConnectionMessage } </div>
				</div>

				<div>{ this.context.initialized ? <div> { 
						this.context.Settings.BlockchainNetworkId === this.context.drizzleState.web3.networkId ?
							<div> 
								<div className="p-1 m-1 text-center small nowrap">
									<StacksPrice price={ accountBalance } />
								</div>
								<div className="p-1 m-1 text-center small">
									<StacksEstPrice price={ accountBalance } peg={ "0" } /> USD
								</div>
							</div>
						:
							<div className="m-1 pt-3 pb-3 text-center small"> 
								Set Wallet Network To
								<br/>
								<strong> "{BlockTxns.purdyCamel(this.context.Settings.networkName)} Network" </strong>
								<br/>
								<a onClick={this.handlePageRefresh} rel="noopener noreferrer" href="#">and refresh</a>
							</div>
					} </div> 
					: <div>Not Initialized</div> 
				}</div>

				</div>
			:
				<div className="p-3 m-3 font-weight-bold text-nowrap">
					Not Connected
				</div>
			}
		</Card>
	}
}

export default StacksWallet
