// DrizNetworkName.js 20210418 j0zf at ApogeeINVENT
import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin';

class DrizNetworkName extends React.Component {
	static contextType = DrizzleContext.Context;
	render() {
		var networkName = "";

		if (this.context.initialized) {
			switch (this.context.drizzleState.web3.networkId) {
				case 1:
					networkName = "Ethereum"; break;
				case 3:
					networkName = "Ropsten"; break;
				case 4:
					networkName = "Rinkeby"; break;
				case 5:
					networkName = "Goerli"; break;
				case 6:
					networkName = "Kotti"; break;
				case 61:
					networkName = "Classic"; break;
				case 63:
					networkName = "Mordor"; break;
				case 137:
					networkName = "Polygon"; break;
				case 212:
					networkName = "Astor"; break;
				case 2018: case 5777:
					networkName = "Devnet"; break;
				case 11611:
					networkName = "StacksNET"; break;
				case 11612:
					networkName = "StacksTEST"; break;
				case 80001:
					networkName = "Mumbai"; break;
				default:
					networkName = "Unknown";
			}
		}
		return <>{networkName}</>;
	}
}

export default DrizNetworkName;
