// HoldingsPane.js - 2021-04-11 j0zf

import React from "react";
import { DrizzleContext } from '@drizzle/react-plugin';
import BlockTxns from "../utility/BlockTxns.js"
import equal from 'fast-deep-equal'
import StacksEstPrice from "../stacks/StacksEstPrice.js"

class HoldingsPane extends React.Component {
	state = { stateProps: this.props }
	static contextType = DrizzleContext.Context;
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState({ stateProps: this.props }, this.queryContracts)
		}
	}
	queryContracts = () => {
		var _Manager_Role_ = "2" // uint32 constant _Manager_Role_ = 2; 
		var _Banker_Role_ = "4" // uint32 constant _Banker_Role_ = 4;
		BlockTxns.contractCacheCall(this, "AppLogic", "hasRole", { roleType: _Manager_Role_, account: this.context.account }, "hasRole_Manager")
		BlockTxns.contractCacheCall(this, "AppLogic", "hasRole", { roleType: _Banker_Role_, account: this.context.account }, "hasRole_Banker")
	}

	render() {
		let hasManagerRole = BlockTxns.getCacheValue(this, "AppLogic", "hasRole", "hasRole_Manager", false)
		let hasBankerRole = BlockTxns.getCacheValue(this, "AppLogic", "hasRole", "hasRole_Banker", false)
		let account = this.props.queryObject.account && this.props.queryObject.account !== '' ? this.props.queryObject.account : this.context.account
		let accountOwner = this.context.account === account

		const holdings = [
			{name: 'Royalties', acc: 1, withdrawMode: 2},
			{name: 'Sales', acc: 2, withdrawMode: 2},
			{name: 'General', acc: 3, withdrawMode: 1},
			{name: 'Bonuses', acc: 4, withdrawMode: 1},
			{name: 'Refunds', acc: 5, withdrawMode: 1},
			{name: 'Bids Escrow', acc: 6, withdrawMode: 0},
			{name: 'Trades Escrow', acc: 7, withdrawMode: 0},
			{name: 'Auctions Escrow', acc: 8, withdrawMode: 0},
			{name: 'Tax Escrow', acc: 9, withdrawMode: 0},
			{name: 'Withdrawal Escrow', acc: 10, withdrawMode: 0},
		];

		const pools = [
			{name: 'House Pool', acc: 1},
			{name: 'Bonus Pool', acc: 2},
			{name: 'Tax Pool', acc: 3},
			{name: 'Withdrawal Pool', acc: 4},
		];

		return ( <div>
			<h2>Contract Holdings</h2>
			<h4 className="text-muted">
				{ accountOwner ? <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp; Your Holdings &nbsp;</span> : <span>Their Holdings </span> } 
			</h4>
			<h7 className="text-muted m-2 xs"> <a className="xs" href={"./listings?owner=" + account}> { account } </a> </h7>

			<div style={{width: '60%'}}>
				<dl className="row">
					{holdings.map((hodl, i) => {
						return ( <>
							<dt key={i+"_dt"} className="col-sm-4 mb-2 small"> 
								<span key={i+"_accpill"} className="badge badge-light badge-pill">Acc {hodl.acc}</span> 
								{hodl.name}
							</dt>
							<dd key={i+"_dd"} className="col-sm-8 small bg-light text-muted">
								<HoldingsAccount accountType={hodl.acc} account={account} withdrawMode={ accountOwner ? hodl.withdrawMode : 0 } />
							</dd>
						</> )
					})}
				</dl>
			</div>
			{ hasManagerRole || hasBankerRole ? 
				<div style={{width: '60%'}}>
					<h3 className="text-muted"> Contract Pools </h3>
					<p>
						<span> Manager? { hasManagerRole ? 'Yes' : 'No' } </span>
						<span> | </span>
						<span> Banker? { hasBankerRole ? 'Yes' : 'No' } </span>
					</p>
					<dl className="row">
						{pools.map((hodl, i) => {
							return ( <>
								<dt key={i+"_dt2"} className="col-sm-4 mb-2 small"> 
									<span key={i+"_accpill2"} className="badge badge-light badge-pill">Pool {hodl.acc}</span> 
									{hodl.name} 
								</dt>
								<dd key={i+"_dd2"} className="col-sm-8 small bg-light text-muted text-truncate">
									<HoldingsPool poolType={hodl.acc} withdrawMode={ hasBankerRole ? 1 : 0 } />
								</dd>
							</> )
						})}
					</dl>
				</div>
			: <div></div> }
		</div> )
	}
}

class HoldingsPool extends React.Component {
	state = { 
		poolType: this.props.poolType ? this.props.poolType : 0, 
		withdrawMode: this.props.withdrawMode ? this.props.withdrawMode : 0
	}
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "AccountingLogic", "getPoolBalance", { poolType: this.state.poolType }, "getPoolBalance_1")
	}
	render() {
		let getPoolBalance_1 = BlockTxns.getCacheValue(this, "AccountingLogic", "getPoolBalance", "getPoolBalance_1", "0")
		return <span> 
			{ BlockTxns.currDraw(this, getPoolBalance_1) }
			<StacksEstPrice price={getPoolBalance_1} peg={"0"} />
			{
				this.state.withdrawMode < 1 ?  "Reserved" 
				: 
					<span>
						<a href={"./forms?contract=AccountingLogic&method=withdrawPool&poolType=" + this.state.poolType + "&amount=" + getPoolBalance_1}>Withdraw</a>
					</span>
			}
		</span>
	}
}

class HoldingsAccount extends React.Component {
	state = { 
		accountType: this.props.accountType ? this.props.accountType : 0, 
		account: this.props.account ? this.props.account : "",
		withdrawMode: this.props.withdrawMode ? this.props.withdrawMode : 0
	}
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		BlockTxns.contractCacheCall(this, "AccountingLogic", "getAccountBalance", { accountType: this.state.accountType, account: this.state.account }, "getAccountBalance_1")
	}
	render() {
		let getAccountBalance_1 = BlockTxns.getCacheValue(this, "AccountingLogic", "getAccountBalance", "getAccountBalance_1", "0")
		return <span> 
			{BlockTxns.currDraw(this, getAccountBalance_1)}
			<StacksEstPrice price={getAccountBalance_1} peg={"0"} />
			{
				this.state.withdrawMode < 1 ?  "Reserved" 
				: 
					<span>
						<a href={"./withdrawal?accountType=" + this.state.accountType + "&withdrawMode=1&fromAccount=" + this.state.account + "&toAccount=" + this.state.account + "&amount=" + getAccountBalance_1}>Withdraw</a>
					</span>
			}
		</span>
	}
}

export default HoldingsPane
