import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import StrangeDice from './utility/StrangeDice.js'

class AppErrorBoundary extends React.Component {
	// Ref https://reactjs.org/docs/error-boundaries.html
	state = { hasError: false }
	refreshTimeout = null

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		console.log("App Error Boundry", error, errorInfo)
		//setTimeout(window.location.reload, 5000)
		this.setState({ hasError: true })
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			this.refreshTimeout = setTimeout(() => { document.location.reload() }, 4000) // 4000 is 4 seconds
			// return <StrangeDice />
			return <div>... <StrangeDice /></div>
			// return <div>Something went wrong.</div>
		}

		return this.props.children
	}
}

try {
	ReactDOM.render(<AppErrorBoundary><App /></AppErrorBoundary>, document.getElementById('root'))
} catch(err) {
	console.log("ReactDOM.render() App ERROR =>", err)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
