// SeriesPane.js 20220624 j0zf at apogeeinvent.com
// TokenPane.js 20210415 j0zf at ApogeeINVENT

import React from "react"
import { DrizzleContext } from '@drizzle/react-plugin'
import { Container, Input } from "reactstrap"
import equal from "fast-deep-equal"
import StacksSeriesById from "../stacks/StacksSeriesById"
import StacksSeriesDetails from "../stacks/StacksSeriesDetails"

class SeriesPane extends React.Component {
	state = { fields: { seriesId: this.props.queryObject.seriesId }, contractId: 1 }
	static contextType = DrizzleContext.Context;

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	getFieldState = (fname) => {
		return (this.state.fields[fname] === undefined ? "" : this.state.fields[fname]);
	}

	handleFieldChange = (e) => {
		var fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({fields});
	}

	renderORIG() {
		return (
		<Container>
			<div className="clearfix">
				<div>
					<h2>Mintable Token Series</h2>
				</div>
				<div style={{ 'width': '60%', 'minWidth': '30rem', 'maxWidth': '40rem', 'float': 'left' }}>
					<dl className="row">
						<dt className="col-sm-4 mb-2 pt-2"> Series Id </dt>
						<dd className="col-sm-8 text-truncate small">
							<Input className="m-1" type="number" name="seriesId" placeholder="Series Id" value={this.getFieldState('seriesId')} onChange={this.handleFieldChange} min="0" step="1" />
						</dd>
					</dl>
					<StacksSeriesDetails seriesId={this.state.fields.seriesId} contractId={this.state.contractId} />
				</div>
				<div style={{'width': '20rem', 'float': 'left'}} className="ml-4 mt-4">
					<StacksSeriesById seriesId={this.state.fields.seriesId} contractId={this.state.contractId} />
				</div>
			</div>
		</Container>
		)
	}

	render() {
		return (
		<Container>
			<div className="clearfix">
				<div>
					<h2>Mintable Token Series</h2>
				</div>
				{/* 
				<div style={{ 'width': '60%', 'minWidth': '30rem', 'maxWidth': '40rem', 'float': 'left' }}>
				<div style={{ 'width': '90%', 'minWidth': '16rem', 'maxWidth': '40rem', 'overflow': 'hidden' }}>
				<div style={{ 'width': '100%', 'minWidth': '16rem', 'maxWidth': '40rem', 'overflow': 'auto' }}>
				*/}
				<div style={{ 'width': '100%', 'minWidth': '16rem' }}>
					<dl className="row">
						<dt className="col-sm-4 mb-2 pt-2"> Series Id </dt>
						<dd className="col-sm-8 text-truncate small">
							<Input className="m-1" type="number" name="seriesId" placeholder="Series Id" value={this.getFieldState('seriesId')} onChange={this.handleFieldChange} min="0" step="1" />
						</dd>
					</dl>
					<StacksSeriesDetails seriesId={this.state.fields.seriesId} contractId={this.state.contractId} />
				</div>
				{/* 
				<div style={{'width': '20rem', 'float': 'left'}} className="ml-4 mt-4">
					<StacksSeriesById seriesId={this.state.fields.seriesId} contractId={this.state.contractId} />
				</div>
				*/}
			</div>
		</Container>
		)
	}
}

export default SeriesPane
