// BlockTxns.js j0zf 2022 0607

import React from "react"
import sha256 from 'crypto-js/sha256'

const BlockTxns = {

	contractCacheCallByApi: (component, contract, method, fields, keyName) => {
		if (!component.context.drizzle || !component.context.drizzle.web3 || !component.context.drizzle.web3.utils ) return;
		const w3u = component.context.drizzle.web3.utils
		const cacheKeyName = 'cacheByApi_' + contract + '_' + method + '_' + keyName
		const cacheKeyValue = 'cacheKeyValue_' + sha256( JSON.stringify(fields) + cacheKeyName )
		const M = BlockTxns.getMethodAbi(component, contract, method)
		try {
			const fieldTypes = M.inputs.map((inp) => { return inp.type })
			const fieldValues = M.inputs.map((inp) => { return ( fields[inp.name] === undefined ? "" : fields[inp.name] ) })
			const apiURL = `${component.context.Settings["StacksBlkApi"]}/blockapi/${contract}/${method}(${fieldTypes.join(',')})?inputs=${escape(JSON.stringify(fieldValues))}`
			// ^^ const apiURL = component.context.Settings["StacksBlkApi"] + '/blockapi/' + contract + '/' + method + '(' + fieldTypes.join(',') + ')' + '?inputs=' + escape(JSON.stringify(fieldValues))
			if (component.state[cacheKeyName] === undefined || component.state[cacheKeyValue] === undefined) {
				console.log("FETCH(" + apiURL + ")")
				fetch(apiURL, { method: 'GET', credentials: 'include' }).then(res => {
					return res.json()
				}).then(data => {
					if (data.status && data.status === "success") {
						console.log("SUCCESS data => ", data)
						// vv const dataResult = ( typeof data.result !== "object" && typeof data.result !== "array" ? { "0": data.result } : data.result )
						// const dataResult = ( !!data.result && Object.isObject(data.result) && Array.isArray(data.result) ? { "0": data.result } : data.result )
						const dataResult = ( typeof data.result !== "object" && Array.isArray(data.result) ? { "0": data.result } : data.result )
						var cacheData = {}
						for (var mo in M.outputs ) {
							var outRow = M.outputs[mo]
							var dataName = outRow.name === '' ? ''+mo : outRow.name 
							switch (outRow.type) {
								case 'uint256': cacheData[dataName] = w3u.hexToNumberString( dataResult[dataName] !== '' ? '0x'+dataResult[dataName] : '0x0' ).toString(); break
								default: cacheData[dataName] = dataResult[dataName]
							}
						}
						var stateJson = {}
						stateJson[cacheKeyName] = cacheKeyValue 
						stateJson[cacheKeyValue] = Object.keys(cacheData).length === 1 && cacheData["0"] !== undefined ? cacheData["0"] : cacheData
						component.setState(stateJson)
					} else {
						console.log("FAIL data => ", data)
					}
				}).catch(err => {
					console.log("catch error => ", err)
					console.log("contractCacheCallByApi() Caught Fetch Error", err)
				})
			} else {
				console.log("SKIPPING(" + apiURL + ")")
				var stateJson = {}
				stateJson[cacheKeyName] = cacheKeyValue 
				stateJson["contractCacheCallByApi_refresh"] = ( component.state["contractCacheCallByApi_refresh"] === undefined ? 1 : component.state["contractCacheCallByApi_refresh"]++ )
				component.setState(stateJson)
			}
		} catch (err) {
			console.log(err)
		}
	},

	// deprecated, or abandoned?? #review 
	getCacheValueByApi: (component, contract, method, keyName, defaultValue) => {
		const cacheKeyName = 'cacheByApi_' + contract + '_' + method + '_' + keyName
		const cacheKeyValue = ( component.state[cacheKeyName] !== undefined ? component.state[cacheKeyName] : null )
		const cacheData = ( component.state[cacheKeyValue] !== undefined ? component.state[cacheKeyValue] : null )
		console.log("getCacheValueByApi: cacheData => ", cacheData, ' stringified() ', JSON.stringify(cacheData))
		// return (cacheData !== null ? ( cacheData.length === 1 && cacheData["0"] !== undefined ? cacheData["0"] : cacheData ) : defaultValue)
		return ( cacheData !== null ? cacheData : defaultValue )
	},

	contractCacheCall: (component, contract, method, fields, keyName) => {
		if (!component.context.drizzle || !component.context.drizzle.web3 || !component.context.drizzle.web3.utils ) return;
		const C = component.context.drizzle.contracts[contract]
		const M = BlockTxns.getMethodAbi(component, contract, method)
		const w3u = component.context.drizzle.web3.utils
		const drizzleState = component.context.drizzleState
		try {
			const txValues = M.inputs.map((inp, i) => {
				const v = (fields[inp.name] === undefined ? "" : fields[inp.name])
				switch (inp.type) {
					case 'address': return (w3u.isAddress(v) ? v : '0x0000000000000000000000000000000000000000')
					case 'string': return ""+v
					case 'bool': return (v.toLowerCase() === "true" ? true : false)
					default: return w3u.toHex(v)
				}
			})
			const txOptions = {from: drizzleState.accounts[0]}
			var cacheKeyValue = C.methods[method].cacheCall(...txValues, txOptions)
			var stateJson = '{ "cacheKey_'+keyName+'": "'+cacheKeyValue+'" }'
			component.setState(JSON.parse(stateJson))
		} catch (error) {
			console.log(error)
		}
	},

	getCacheValue: (component, contract, method, keyName, defaultValue) => {
		try {
			const contractState = component.context.drizzleState.contracts[contract]
			const w3u = component.context.drizzle.web3.utils
			const cacheKey = (component.state["cacheKey_"+keyName] !== undefined ? component.state["cacheKey_"+keyName] : null)
			if (contractState && cacheKey && contractState[method][cacheKey]) {
				const cache = contractState[method][cacheKey]
				if (cache && cache.value) {
					if (typeof cache.value === "object" && Object.keys(cache.value).length === 4 && cache.value["3"] !== undefined && Array.isArray(cache.value["0"])) {
						// DYNAMIC FIELDS DETECTED
						// format fields, numbers, addresses, strings (bytes32) "fnas"
						// format  type|fieldsname  eg. n|tokenId
						var F=0, N=1, A=2, S=3 // arg location of fields
						var f=0, n=0, a=0, s=0 // count of field type parsed
						for (f in cache.value[F]) {
							var [vtype, vname] = w3u.toAscii(cache.value[F][f]).replace(/\0/g, '').split('|')
							if (vtype && vname) {
								vname = vname.trim()
								switch(vtype) {
									case 'n': cache.value[vname] = cache.value[N][n++].toString(); break // n|number type
									case 'a': cache.value[vname] = cache.value[A][a++]; break // a|address type
									case 's': cache.value[vname] = w3u.toAscii(cache.value[S][s++]).replace(/\0/g, '').trim(); break // s|string type
									default: break
								}
							}
						}
					}
					return cache.value
				}
			}
		} catch (error) {
			console.log(error)
		}
		return defaultValue
	},

	cacheAccountBalance: (component, account) => {
		try {
			let drizzle = component.context.drizzle
			drizzle.web3.eth.getBalance(account, (err, result) => {
				if (!err) {
					let _account_balance_cache_ = component.state._account_balance_cache_ ? component.state._account_balance_cache_ : {}
					_account_balance_cache_[account] = result
					component.setState({_account_balance_cache_})
				}
			})
		} catch(err) {

		}
	},

	getCacheAccountBalance: (component, account) => {
		if (component.state._account_balance_cache_ && component.state._account_balance_cache_[account]) {
			return component.state._account_balance_cache_[account]
		} else {
			return "0"
		}
	},

	getMethodAbi: (component, contractName, methodName) => {
		if (component.context.drizzle && component.context.drizzle.contracts[contractName] !== undefined) {
			const C = component.context.drizzle.contracts[contractName]
			for (var xx in C.abi) {
				if (C.abi[xx].name !== undefined && C.abi[xx].name === methodName) {
					return C.abi[xx]
				}
			}
		} else if (component.context.drizzle && contractName === 'WalletTools') {
			// RETURN SIMULATED ABI FOR WEB3 NON-CONTRACT UTILITY FUNCTIONALITY
			switch (methodName) {
				case 'sendFunds':
					console.log('DEBUGGER sendFunds Selected')
					return { name: methodName, payable: true, inputs: [ { type: 'address', name: 'to' } ] }
				default: 
				return null
			}
		}
		return null
	},

	// #nix #temp #bak getMethodAbi: (component, contractName, methodName) => {
	// #nix #temp #bak 	if (component.context.drizzle && component.context.drizzle.contracts[contractName] !== undefined) {
	// #nix #temp #bak 		const C = component.context.drizzle.contracts[contractName]
	// #nix #temp #bak 		for (var xx in C.abi) {
	// #nix #temp #bak 			if (C.abi[xx].name !== undefined && C.abi[xx].name === methodName) {
	// #nix #temp #bak 				return C.abi[xx]
	// #nix #temp #bak 			}
	// #nix #temp #bak 		}
	// #nix #temp #bak 	}
	// #nix #temp #bak 	return null
	// #nix #temp #bak },

	purdyCamel: (str) => {
		// UTILITY TO CLEAN UP NAME FIELDS FOR DISPLAY
		var s = str.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").replace("_", " ").trim()
		return s.charAt(0).toUpperCase() + s.slice(1)
	},

	currSymbol: ( curr, opts ) => {
		switch (curr) {
			case 'Eth':
			case 'Matic':
			case 'Stacks':
			case 'Ganache':
			default: 
				return <img height={ opts && opts.height ? opts.height : '16' } className={ opts && opts.className ? opts.className : '' } alt={ curr + " Token Currency" } src={ "./images/TokenIcon_" + curr + ".png" } />
		}
	},

	currDraw: (component, price, opts) => {
		try {
			const w3u = component.context.drizzle.web3.utils
			var priceEth = "0"
			try {
				priceEth = w3u.fromWei(price, 'ether')
			} catch (err) {
				
			}
			// return <span> {val} <img height="18" alt="Stacks Credits" src="./Stacks192.png" /> </span>
			// return <span> {priceEth}&nbsp;<img height={ opts && opts.height ? opts.height : '18' } alt="Stacks Credits" src="./Stacks192.png" /> </span>
			return <span> {priceEth}&nbsp;{ BlockTxns.currSymbol(component.context.Settings.curr, opts) } </span>

		} catch(err) {
			// if(!component.context.initialized) return <span></span>
			return <span></span>

		}

	},

	walletToolsSendFunds: async (component, contract, method, fields, keyName) => {
		// Wallet Tool Send - analog to the contractSend method - this is for the "Utility" Pseudo contract, for things such as web3 send funds
		// #eg walletToolsSendFunds(component, contract=>WalletTools, method=>sendFunds, fields=>{"to":"0x231E9a7aAee40eE24a20eb9a43A794Fd14a515D4","_payableAmount_":"123000000000000000"}, keyName=>StacksFormKey)
		// #ref https://ethereum.stackexchange.com/questions/42929/send-payment-from-wallet-using-web3
		console.log("walletToolsSendFunds(component, contract=>"+contract+", method=>"+method+", fields=>"+JSON.stringify(fields)+", keyName=>"+keyName+")")
		try {
			var _cacheKeys_ = component.state._cacheKeys_ ? component.state._cacheKeys_ : {}
			_cacheKeys_[keyName] = { isWeb3: true } // new txn, so wipe previous results
		
			const w3u = component.context.drizzle.web3.utils
			const web3 = component.context.drizzle.web3
			var _payableAmount_ = component.state.fields._payableAmount_ === undefined ? new w3u.BN("0") : new w3u.BN(component.state.fields._payableAmount_)
			var from_addr = component.context.account
			var to_addr = component.state.fields.to
			console.log(`web3.eth.sendTransaction({from: ${from_addr}, to: ${to_addr}, value: ${_payableAmount_} })`)

			if (_payableAmount_.toString() === "0") {
				throw new Error("Error, please set a value greater than 0.")
			}

			// // #todo #inprog 
			// let receipt = await web3.eth.sendTransaction({from: from_addr, to: to_addr, value: _payableAmount_ })
			// _cacheKeys_[keyName]["receipt"] = receipt
			// component.setState({ _cacheKeys_ })
			// console.log("BlockTxns.walletToolsSendFunds() =>", "receipt", receipt)

			const nonce = await web3.eth.getTransactionCount(from_addr, 'latest')
			const gasPrice = await BlockTxns.guessGasPrice(component)
			const txData = { 
				from: from_addr,
				value: _payableAmount_.toString(), 
				to: to_addr,
				gas: component.gasLimit, 
				maxFeePerGas: gasPrice, 
				maxPriorityFeePerGas: gasPrice,
				nonce: nonce,
				data: "",
			}
			
			const signedTxData = await web3.eth.signTransaction(txData)
			console.log("signedTxData:", signedTxData)

			if (!signedTxData) {
				throw new Error("Unable to sign the transaction.")
			} 

			web3.eth.sendSignedTransaction(signedTxData.raw)
			.once("transactionHash", (hash) => {
				_cacheKeys_[keyName]["web3TxHash"] = hash
				component.setState({ _cacheKeys_ })
				console.log("BlockTxns.walletToolsSendFunds() =>", "transactionHash", hash)
			})
			.once("receipt", (receipt) => {
				_cacheKeys_[keyName]["receipt"] = receipt
				component.setState({ _cacheKeys_ })
				console.log("BlockTxns.walletToolsSendFunds() =>", "receipt", receipt)
			})
			.on("confirmation", (confirmationNumber, receipt) => {
				_cacheKeys_[keyName]["confirmation"] = { confirmationNumber, receipt }
				// _cacheKeys_[keyName]["confirmation"][receipt['transactionHash']] = confirmationNumber
				component.setState({ _cacheKeys_ })
				console.log("BlockTxns.walletToolsSendFunds() =>", "confirmation", { confirmationNumber, receipt })
			})
			.on("error", (error, receipt) => {
				_cacheKeys_[keyName]["error"] = { error, receipt }
				component.setState({ _cacheKeys_ })
				console.log("BlockTxns.walletToolsSendFunds() =>", "error", { error, receipt })
			})
			.then(console.log)

		} catch(error) {
			console.log("walletToolsSendFunds ERROR: ", error)
			_cacheKeys_[keyName]["error"] = { error }
			component.setState({ _cacheKeys_ })
		}
	},

	guessGasPrice: async (component) => {
		try {
			const web3 = component.context.drizzle.web3
			const w3u = web3.utils
			const gasPriceGot = await web3.eth.getGasPrice()
			// const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('5').div(new w3u.BN('4')))).toString()
			// const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('15').div(new w3u.BN('11')))).toString()
			// const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('2').div(new w3u.BN('1')))).toString()
			// const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('3').div(new w3u.BN('2')))).toString()
			const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('2'))).toString()
			return gasPrice
		} catch (error) {
			console.log("vv ERROR BlockTxns.guessGasPrice() vv")
			console.log(error)
			console.log("^^ ERROR BlockTxns.guessGasPrice() ^^")
			// #nix _cacheKeys_[keyName]["error"] = { error }
			// #nix component.setState({ _cacheKeys_ })
			return '0'
		}
	},

	contractSend: async (component, contract, method, fields, keyName) => {
		var _cacheKeys_ = component.state._cacheKeys_ ? component.state._cacheKeys_ : {}
		_cacheKeys_[keyName] = { isWeb3: true } // new txn, so wipe previous results

		try {
			const DC = component.context.drizzle.contracts[contract]
			const C = new component.context.drizzle.web3.eth.Contract( DC.abi, DC.address )
			console.log("component.context.drizzle.web3.eth.contracts[contract!!"+contract+"] => C", C)
			const M = BlockTxns.getMethodAbi(component, contract, method)
			// #unused const web3 = component.context.drizzle.web3
			const w3u = component.context.drizzle.web3.utils
			const drizzleState = component.context.drizzleState
			var _payableAmount_ = component.state.fields._payableAmount_ === undefined ? new w3u.BN("0") : new w3u.BN(component.state.fields._payableAmount_)
			const txValues = M.inputs.map((inp, i) => {
				const v = component.getFieldState(inp.name)
				switch (inp.name) {
					default:
						switch (inp.type) {
							case 'address': return (w3u.isAddress(v) ? v : '0x0000000000000000000000000000000000000000')
							case 'string': return ""+v
							case 'bool': return (v.toLowerCase() === "true" ? true : false)
							// case 'uint256[5]': // todo extensibility 
							// 	var arr = []
							// 	for(var arri=0; arri<5; arri++ ) {
							// 		arr.push(w3u.toHex( fields["tokenIds["+arri+"]"] === undefined ? 0 : fields["tokenIds["+arri+"]"] ))
							// 	}
							// return arr
							default: return w3u.toHex(v)
						}
				}
			})
			// #nix const gasPriceGot = await web3.eth.getGasPrice()
			// #nix // const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('5').div(new w3u.BN('4')))).toString()
			// #nix // const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('15').div(new w3u.BN('11')))).toString()
			// #nix // const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('3').div(new w3u.BN('2')))).toString()
			// #nix // const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('2').div(new w3u.BN('1')))).toString()
			// #nix const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('2'))).toString()
			// #nix const gasPrice = await BlockTxns.guessGasPrice(component)
			// const gasPriceGot = await web3.eth.getGasPrice()
			// const gasPrice = (new w3u.BN(gasPriceGot).mul(new w3u.BN('2'))).toString()
			const gasPrice = await BlockTxns.guessGasPrice(component)

			console.log("BlockTxns::contractSend, gasPrice => ("+gasPrice+")")

			// #todo component.gasLimit is probably not even defined in most cases. bad expectation / way to pass a param.. relic... ~j0zf 
			// const txOptions = { from: drizzleState.accounts[0], value: _payableAmount_.toString(), gas: component.gasLimit, gasPrice: gasPrice }
			const txOptions = { from: drizzleState.accounts[0], value: _payableAmount_.toString(), gas: component.gasLimit, maxFeePerGas: gasPrice, maxPriorityFeePerGas: gasPrice }

			console.log("txOptions:", JSON.stringify(txOptions)) // #debugger

			C.methods[method](...txValues).send(txOptions)
			.on("transactionHash", (hash) => {
				_cacheKeys_[keyName]["web3TxHash"] = hash
				component.setState({ _cacheKeys_ })
				console.log("BlockTxns.contractSend() =>", "transactionHash", hash)
			})
			.on("receipt", (receipt) => {
				_cacheKeys_[keyName]["receipt"] = receipt
				component.setState({ _cacheKeys_ })
				console.log("BlockTxns.contractSend() =>", "receipt", receipt)
			})
			.on("confirmation", (confirmationNumber, receipt) => {
				_cacheKeys_[keyName]["confirmation"] = { confirmationNumber, receipt }
				component.setState({ _cacheKeys_ })
				// console.log("BlockTxns.contractSend() =>", "confirmation", { confirmationNumber, receipt })
			})
			.on("error", (error, receipt) => {
				_cacheKeys_[keyName]["error"] = { error, receipt }
				component.setState({ _cacheKeys_ })
				console.log("BlockTxns.contractSend() =>", "error", { error, receipt })
			})
		} catch (error) {
			console.log("vv BlockTxns.contractSend() vv")
			console.log(error)
			console.log("^^ BlockTxns.contractSend() ^^")
			_cacheKeys_[keyName]["error"] = { error }
			component.setState({ _cacheKeys_ })
		}
	},

	contractCacheSend: (component, contract, method, fields, keyName) => {
		try {
			const C = component.context.drizzle.contracts[contract]
			const M = BlockTxns.getMethodAbi(component, contract, method)
			const w3u = component.context.drizzle.web3.utils
			const drizzleState = component.context.drizzleState
			var _payableAmount_ = component.state.fields._payableAmount_ === undefined ? new w3u.BN("0") : new w3u.BN(component.state.fields._payableAmount_)
			const txValues = M.inputs.map((inp, i) => {
				const v = component.getFieldState(inp.name)
				switch (inp.name) {
					// case 'askingPrice': return w3u.toWei(new w3u.BN(v), 'ether')
					default:
						switch (inp.type) {
							case 'address': return (w3u.isAddress(v) ? v : '0x0000000000000000000000000000000000000000')
							case 'string': return ""+v
							case 'bool': return (v.toLowerCase() === "true" ? true : false)
							case 'uint256[5]': // todo extensibility 
								var arr = []
								for(var arri=0; arri<5; arri++ ) {
									arr.push(w3u.toHex( fields["tokenIds["+arri+"]"] === undefined ? 0 : fields["tokenIds["+arri+"]"] ))
								}
							return arr
							default: return w3u.toHex(v)
						}
				}
			})
			const txOptions = { from: drizzleState.accounts[0], value: _payableAmount_.toString(), gas: component.gasLimit }
			var _cacheKeys_ = component.state._cacheKeys_ ? component.state._cacheKeys_ : {}
			console.log("BlockTxns.contractSend() _cacheKeys_[keyName!!"+keyName+"] = C.methods[method!!"+method+"].cacheSend(...txValues!!"+JSON.stringify(txValues)+", txOptions!!"+JSON.stringify(txOptions)+")")
			_cacheKeys_[keyName] = C.methods[method].cacheSend(...txValues, txOptions)

			const _drizzleState_ = component.context.drizzle.store.getState()
			component.setState({ _cacheKeys_, _drizzleState_ })
		} catch (error) {
			console.log("vv BlockTxns.contractCacheSend() vv")
			console.log(error)
			console.log("^^ BlockTxns.contractCacheSend() ^^")
		}
	},

	getTxStatus: (component, keyName, defaultValue, onSuccess, onFail) => {
		const cacheKey = (component.state._cacheKeys_ !== undefined && component.state._cacheKeys_[keyName] !== undefined ? component.state._cacheKeys_[keyName] : null);

		if (cacheKey && cacheKey.isWeb3) {
			// using web3 contractSend Method
			// the cacheKey is used as the web3 transaction results object instead.
			if (cacheKey.error) {
				if (onFail !== undefined) return onFail(cacheKey)
				else return 'Error'
			} else if (cacheKey.confirmation) {
				if (onSuccess !== undefined) return onSuccess(cacheKey)
				else return 'Success'
			}
		} else {
			// using drizzle contractCacheSend Method
			const { transactions, transactionStack } = component.context.drizzleState;
			const txHash = transactionStack[cacheKey];
			if (!txHash || transactions[txHash] === undefined) return defaultValue;
			if (transactions[txHash].status === 'success') {
				if (onSuccess !== undefined) return onSuccess(transactions[txHash])
				else return 'Success'
			} else if (transactions[txHash].status === 'error') {
				if (onFail !== undefined) return onFail(transactions[txHash])
				else return 'Error'
			}
		}
		return defaultValue
	},

	getCurrencyEst: async (component, wei, curr) => {
		//const w3u = component.context.drizzle.web3
		console.log("getCurrencyEst: async (component, wei, curr) => {", wei, curr) 
		switch (curr) {
			case 'USD':
				console.log("getCurrencyEst: AGAIN", wei, curr) 
				//result_['balanceOf'] = await glue.blockchain.contracts['ERC721Logic'].methods['balanceOf(address)'](account_, { "from": glue.blockchain.accounts[0] })
				var multiplier = await component.context.drizzle.contracts['AccountingLogic'].methods['getConversion(uint32,uint256)'](1, 1).call()
				//var multiplier = await component.context.drizzle.contracts.AccountingLogic.methods.getConversion(1, 1)
				component.setState({ multiplier })
				console.log("getCurrencyEst: multiplier", multiplier) 

				// if ( component.state.multiplier === undefined ) {
				// 	var multiplier = await component.context.drizzle.contracts.AccountingLogic.methods.getConversion(1, 1)
				// 	component.setState(multiplier)
				// }
				break
			default:
				console.log('BlockTxns:: getCurrencyEst Unsupported Currency', curr)
		}
	},

	getConnectedNetwork: (component) => {
		if (component.context.initialized) {
			switch (component.context.drizzleState.web3.networkId) {
				case 1: return "ethereum"
				case 3: return "ropsten"
				case 4: return "rinkeby"
				case 5: return "goerli"
				case 6: return "kotti"
				case 61: return "classic"
				case 63: return "mordor"
				case 137: return "polygon"
				case 212: return "astor"
				case 2018: case 5777: return "devnet"
				case 11611: return "stackstest"
				case 11612: return "stacksnet"
				case 80001: return "mumbai"
				default: return ""
			}
		}
		return ""
	}

}

export default BlockTxns
