import React from "react"
import { DrizzleContext } from "@drizzle/react-plugin"
import { Drizzle, generateStore } from "@drizzle/store"
import drizzleOptions from "./drizzleOptions"
import "./App.css"
import Main from "./layout/Main"
import StrangeDice from "./utility/StrangeDice"

class App extends React.Component {
	state = { drizzle: null }
	
	componentDidMount = () => {
		try {
			const drizzleStore = generateStore(drizzleOptions)
			const drizzle = new Drizzle(drizzleOptions, drizzleStore)
			this.setState({drizzle})
		} catch (err) {
			console.log('- Exception in App.js  Initializing Drizzle - ')
			console.log(err)
		}
	}
	
	render() {
		var connectionSuccess = drizzleOptions.connectionSuccess
		const Settings = drizzleOptions.Settings
		if (!this.state.drizzle) return <StrangeDice diceDo={{}} />
		return (
			<DrizzleContext.Provider drizzle={this.state.drizzle} >
				<DrizzleContext.Consumer>
					{ drizzleContext => {
						drizzleContext.Settings = Settings
						const { drizzle, drizzleState, initialized } = drizzleContext
						const accounts = drizzleState && drizzleState.accounts ? drizzleState.accounts : []
						const account = accounts[0] ? accounts[0] : ""
						drizzleContext.account = account
						const accountBalances = drizzleState && drizzleState.accountBalances ? drizzleState.accountBalances : {}
						const transactionStack = drizzleState && drizzleState.transactionStack ? drizzleState.transactionStack : {}
						const transactions = drizzleState && drizzleState.transactions ? drizzleState.transactions : {}
						var auth_data_json = localStorage.getItem('auth_data')
						var auth_data = {}
						if (auth_data_json) {
							auth_data = JSON.parse(auth_data_json)
						}
						drizzleContext.auth_data = auth_data
						let lsDebuggerFlag = localStorage.getItem('debugger')
						drizzleContext.debugger = ( lsDebuggerFlag === undefined || !lsDebuggerFlag || lsDebuggerFlag === '' || lsDebuggerFlag.toLowerCase() === 'false' || lsDebuggerFlag.toLowerCase() === 'off' ? false : true)
						const connectionMode = localStorage.getItem('connectionMode')
						var siteConnections = localStorage.getItem("siteConnections")
						try { siteConnections = JSON.parse(siteConnections) } catch(err) { siteConnections = {} }
						siteConnections = siteConnections ? siteConnections : {}

						return <Main initialized={initialized} connectionSuccess={connectionSuccess} drizzle={drizzle} accounts={accounts} account={account} accountBalances={accountBalances} transactionStack={transactionStack} transactions={transactions} auth_data={auth_data} connectionMode={connectionMode} siteConnections={siteConnections} />
					} }
				</DrizzleContext.Consumer>
			</DrizzleContext.Provider>
		)
	}
}

export default App
