// StacksSeries.js - 20220621 j0zf at apogeeinvent.com
// StacksToken.js - 2021-04-15 j0zf at ApogeeINVENT
// StacksForm.js - 2021-04-14 j0zf at ApogeeINVENT
// StacksPublish.js - 2021-04-11 j0zf at ApogeeINVENT

import React from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown, Card, Row, Col, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, CardFooter } from "reactstrap"
import { DrizzleContext } from '@drizzle/react-plugin'
import equal from 'fast-deep-equal'
import BlockTxns from '../utility/BlockTxns.js'
import StrangeDice from "../utility/StrangeDice.js"
import StacksEstPrice from "../stacks/StacksEstPrice.js"

class StacksSeries extends React.Component {
	state = { listing: this.props.listing }
	static contextType = DrizzleContext.Context
	componentDidMount() {
		this.queryContracts()
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}
	queryContracts = () => {
		if (!this.state.listing || !this.state.listing.seriesId) return;
		BlockTxns.contractCacheCall(this, "Stacks721", "getSeries", { seriesId: this.state.listing.seriesId }, "getSeries_1")
	}
	render() {
		const seriesData = BlockTxns.getCacheValue(this, "Stacks721", "getSeries", "getSeries_1", { seriesId: 0 })
		return <StacksSeries2 listing={this.state.listing} seriesData={ seriesData } />
	}
}

class StacksSeries2 extends React.Component {
	state = { listing: this.props.listing, seriesData: this.props.seriesData, tokenCdnData: {}, isOpen: false }
	static contextType = DrizzleContext.Context

	componentDidMount() {
		this.queryContracts()
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props, prevProps)) {
			this.setState(this.props, this.queryContracts)
		}
	}

	queryContracts = () => {
		if (!this.state.seriesData || !this.state.listing || !this.state.listing.seriesId) return
		this.loadTokenJson()
	}

	loadTokenJson = () => {
		if (!this.state.seriesData || !this.state.seriesData.seriesURI) return
		try {
			const seriesURI = this.state.seriesData.seriesURI
			if (seriesURI !== "") {
				fetch(seriesURI)
				.then((resp) => resp.json())
				.then((respJson) => {
					this.setState({ tokenCdnData: respJson })
				}).catch(error => {
					throw(error)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	navToggle = () => {
		this.setIsOpen(!this.state.isOpen)
	}

	setIsOpen = (flg) => {
		this.setState({ isOpen: flg })
	}

	render() {
		const drizzleState = this.context.drizzleState
		// const w3u = this.context.drizzle.web3.utils
		const tokenOwner = this.state.listing.owner
		const seriesData = this.state.seriesData
		const isOwner = (drizzleState.accounts[0] === tokenOwner ? true : false)
		const seriesListing = this.state.listing
		seriesListing.price = ( seriesListing.price !== undefined ? seriesListing.price : 0 )
		seriesListing.peg = ( seriesListing.peg !== undefined ? seriesListing.peg : 0 )
		seriesListing.nativePrice = ( seriesListing.nativePrice !== undefined ? seriesListing.nativePrice : 0 )

		const tokenCdnData = this.state.tokenCdnData
		var issueCoverUrl = "";
		var softcopyLink = "";
		var issueTitle = "";
		var creatorProfileLink = "";
		var creatorUsername = "";
		var seriesTitle = "";
		if (tokenCdnData.id+"" === "0") {
			issueCoverUrl = tokenCdnData.image;
			softcopyLink = tokenCdnData.external_link;
			issueTitle = tokenCdnData.issue_title;
			creatorProfileLink = tokenCdnData.creator_link;
			creatorUsername = tokenCdnData.creator_name;
			seriesTitle = tokenCdnData.series_title;
		}

		const buyNewURL = "./buynew?listingId=" + this.state.listing.listingId + "&_payableAmount_=" + seriesListing.nativePrice + "&to=" + drizzleState.accounts[0]
		const seriesDetailsLink =  "./series?seriesId=" + Number(this.state.listing.seriesId) 
		const setMintableSaleLink =  "./forms?contract=SalesLogic&method=setMintable&listingId="+ this.state.listing.listingId +"&contractId="+ seriesListing.contractId +"&owner="+ seriesListing.owner +"&seriesId=" + Number(this.state.listing.seriesId) + "&price=" + seriesListing.price + "&peg=" + seriesListing.peg 

		const forSaleFlag = ""+seriesListing.nativePrice !== "0" && parseInt(seriesData.issued) < parseInt(seriesData.limit)

		const oMenu = (
			<ButtonDropdown direction="left" isOpen={this.state.isOpen} toggle={this.navToggle}>
			<DropdownToggle size="sm" color="muted" className="pt-0 pr-t mt-0 mr-0">
				{/* 
				&nbsp;<span>&#9881;</span>&nbsp;
				&nbsp;
				*/}
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem href={seriesDetailsLink} >
					<div> Mintable Token Series </div>
					<div> {seriesData.symbol} </div>
					<div> Issued {seriesData.issued} </div>
					{ ( Number(seriesData.limit) > 0 ? <div>Limited {seriesData.limit}</div> : "" ) }
				</DropdownItem>

				<DropdownItem divider />

				<DropdownItem href={softcopyLink} target="" rel="noopener noreferrer" className="text-muted">
					View Content
					&nbsp;
					<span role="img" aria-label="link">&#128279;</span>
				</DropdownItem>

				{ isOwner ? <>
					<DropdownItem href={setMintableSaleLink}>Set Mintable Sale</DropdownItem>
					</> : "" 
				}

				<DropdownItem href={ buyNewURL }>
					Buy New
				</DropdownItem>
				
			</DropdownMenu>
			</ButtonDropdown>
		);

		/*
							<CardText className="text-bold ml-2 overflow-hidden">
								<span className="text-bold" onClick={this.navToggle}>
									{seriesData.symbol} 
									&nbsp;&nbsp;
									<span>
										<span role="img" aria-label="Mintable Token Series">&#128215;</span>
									</span>
								</span>
							</CardText>
						<Col align="right" className="pt-0 pr-0 mt-0 pt-0">
						</Col>
		*/

		return (
			<Card className="m-1" style={{ width: '16rem', minWidth: '16rem', maxWidth: '16rem' }}>
				<CardHeader className="m-1 p-1" onClick={this.navToggle} style={{ cursor: 'pointer' }}>
					<Row className="flex-nowrap">
						<Col className="col-auto" style={{ width: '15rem' }}>
							<div className="ml-2 overflow-hidden" style={{ textAlign: 'center' }}>
								{seriesData.symbol} 
								&nbsp;|&nbsp;
								{seriesData.symbol !== "" ? 
									<span>
										{/* 
											<img height="20" alt="Mintable Token Series" src="./Stacks192.png" />
											{ BlockTxns.currSymbol(this.context.Settings.curr, { height: '40', className: "pt-2" }) }
										*/}
										{" "}
										{ BlockTxns.currSymbol(this.context.Settings.curr, {}) }
									</span>
								:
									<span>
										<StrangeDice diceDo={{}} />
									</span>
								}
								{ isOwner ? <span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span> : '' }
							</div>
						</Col>
						<Col>
							{oMenu}
						</Col>
					</Row>
				</CardHeader>

				{/* 
				<CardImg top width="100%" src={issueCoverUrl} alt={issueTitle} onClick={this.navToggle} style={{ cursor: 'pointer' }} />
				*/}
				<a href={seriesDetailsLink}>
					<CardImg top width="100%" src={issueCoverUrl} alt={issueTitle} />
				</a>

				{/* 
				<div className="text-center p-1 m-0">
					<a href={softcopyLink} target="" rel="noopener noreferrer" className="text-muted">
						View Unlockable Content
						&nbsp;
						<span role="img" aria-label="link">&#128279;</span>
					</a>
				</div>
				*/}

				{/* 
				<div className="text-center p-1 m-0 text-muted">
					{seriesData.issued}
					{" of "}
					{seriesData.limit}
				</div>
				*/}

				<CardBody className="pt-1 mt=0">
					<CardTitle tag="h6">{issueTitle} <a href={creatorProfileLink} target="" rel="noopener noreferrer">{creatorUsername}</a></CardTitle>
					<CardSubtitle tag="h6" className="mb-2 text-muted">{seriesTitle}</CardSubtitle>
				</CardBody>

				<div className="text-center p-1 m-0 text-muted">
					{seriesData.issued}
					{ seriesData.limit > 0 ? " of " : "" }
					{seriesData.limit}
				</div>
				
				<CardFooter>
					<CardText tag="small" className="text-muted">
						<Row><Col align="right" className="col-4">Type</Col><Col>Mintable Series</Col></Row>
						<Row className="text-truncate">
							<Col align="right" className="col-4">Owner</Col>
							<Col className="text-truncate">
									{ isOwner ?
										<a href={"./series?seriesId=" + Number(this.state.listing.seriesId)}>Your Series<span role="img" aria-label="Owner">&nbsp;&#128526;&nbsp;</span></a>
									:
										<a href={"./listings?owner="+tokenOwner}>{tokenOwner}</a>
									}
							</Col>
						</Row>
						<Row>
							<Col align="right" className="col-4">Asking</Col>
							<Col className="text-truncate">
								{ forSaleFlag ?
									BlockTxns.currDraw(this, seriesListing.nativePrice)
								:
									<strong>Unlisted</strong>
								}
							</Col>
						</Row>
						<Row>
							<Col align="right" className="col-4 text-nowrap"> { "Est. Price" } </Col>
							<Col className="text-truncate">
								{ forSaleFlag ? 
									<StacksEstPrice price={ seriesListing.price } peg={ seriesListing.peg } />
								: 
									<strong>No Mints</strong>
								}
							</Col>
						</Row>
					</CardText>
				</CardFooter>
			</Card>
		)
	}
}

export default StacksSeries;
